import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueCookies from 'vue-cookies'
import VueApexCharts from 'vue-apexcharts'
import Toast, { POSITION } from 'vue-toastification'
import './styles/toastification.css'

Vue.config.productionTip = false

Vue.use(VueCookies)
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

const toastificationOptions = {
  position: POSITION.TOP_RIGHT,
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: 'button',
  icon: true,
  rtl: false
}
Vue.use(Toast, toastificationOptions)

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
