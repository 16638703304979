




















































































































































































































































import Vue from 'vue'
import { mapActions } from 'vuex'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import AppCard from '@/components/AppCard.vue'
import { ApiResponse } from '@/js/api'
import { StreamListResource, WorkflowListResource } from '@/js/schemas'
import { DataTableOptions, formatDate } from '@/js/utils'

@Component({
  computed: {
    // ...mapState('streams', ['streams'])
  },
  methods: {
    ...mapActions('streams', ['retrieveStreamList']),
    ...mapActions('workflows', ['retrieveWorkflowList'])
    // ...mapMutations('streams', ['setStreams'])
  },
  components: {
    AppCard
  }
})
export default class Streams extends Vue {
  streams: Array<StreamListResource> = []
  workflows: Record<string, string> = {}
  nameFilter: string = ''
  creatorFilter: string = ''
  statusFilter: Array<string> = []
  tableHeaders = [
    {
      text: 'UUID',
      align: 'start',
      value: 'uuid',
      class: 'app-table--header',
      cellClass: 'app-table--cell'
    },
    {
      text: 'Name',
      value: 'name',
      class: 'app-table--header',
      cellClass: 'app-table--cell'
    },
    {
      text: 'Status',
      value: 'status',
      class: 'app-table--header',
      cellClass: 'app-table--cell'
    },
    {
      text: 'Progress',
      value: 'progress',
      class: 'app-table--header',
      cellClass: 'app-table--cell'
    },
    {
      text: 'Updated At',
      value: 'updated',
      class: 'app-table--header',
      cellClass: 'app-table--cell'
    },
    {
      text: 'Workflow',
      value: 'workflow',
      class: 'app-table--header',
      cellClass: 'app-table--cell'
    },
    {
      text: 'Creator',
      value: 'account',
      class: 'app-table--header',
      cellClass: 'app-table--cell'
    },
    {
      text: 'Actions',
      value: 'actions',
      class: 'app-table--header',
      cellClass: 'app-table--cell'
    }
  ]

  tableOptions: DataTableOptions = {
    groupBy: [],
    groupDesc: [],
    sortBy: ['updated'],
    sortDesc: [true],
    page: 1,
    itemsPerPage: 10,
    multiSort: false,
    mustSort: false
  }

  tableTotalQuerySet: number = 10

  tableIsLoading: boolean = true

  tableFooterProps = {
    'items-per-page-options': [5, 10, 20, 40],
    'items-per-page-text': 'Paginated by:'
  }

  configNameFilter: string = 'Any kind'

  retrieveStreamList!: (filterParams: Record<string, any>) => Promise<ApiResponse>
  retrieveWorkflowList!: (filterParams: Record<string, any>) => Promise<ApiResponse>
  // setStreams!: (streams: StreamListResource[]) => undefined

  get configNames (): string[] {
    return [
      'Any kind',
      '# blank',
      ...Object.keys(this.workflows)
    ]
  }

  @Watch('tableOptions')
  watchTableOptions (options: DataTableOptions) {
    if (options.sortBy.length === 0) {
      options.sortBy.push('updated')
    }
    if (this.tableTotalQuerySet !== 0) {
      const filters = {
        sort_field: options.sortBy[0],
        sort_order: options.sortDesc[0] ? 'desc' : 'asc'
      }
      this.getStreams(filters)
    }
  }

  fmtDate (value: string | Date): string {
    return formatDate(value)
  }

  buildDataFilters () {
    const listFilters: Record<string, string> = {}
    if (this.statusFilter.length > 0) {
      listFilters.status__in = this.statusFilter.join()
    }
    if (this.nameFilter !== '') {
      listFilters.name__contains = this.nameFilter
    }
    if (this.creatorFilter !== '') {
      listFilters.username = this.creatorFilter
    }
    if (this.configNameFilter !== 'Any kind') {
      if (this.configNameFilter === '# blank') {
        listFilters.workflow = 'null'
      } else {
        listFilters.workflow = this.workflows[this.configNameFilter]
      }
    }
    return listFilters
  }

  applyStreamListFilters () {
    this.tableOptions.page = 1
    this.getStreams(this.buildDataFilters())
  }

  retrieveWorkflows () {
    const defaultFilters = { sort_field: 'created', sort_order: 'asc', limit: 1000 }
    this.retrieveWorkflowList({ ...defaultFilters }).then(
      (apiResponse: ApiResponse) => {
        if (!apiResponse.isError && apiResponse.content.status === 200) {
          const workflowsList = (apiResponse.content.body.data as WorkflowListResource[])
          const workflowsMap: Record<string, string> = {}
          for (let i = 0; i < workflowsList.length; i++) {
            const value = workflowsList[i]
            workflowsMap[value.config_name] = value.uuid
          }
          this.workflows = { ...workflowsMap }
        } else {
          // TODO: handle error
        }
      })
  }

  getStreams (listFilters: Record<string, string> = {}) {
    this.tableIsLoading = true
    const paginationFilters = {
      limit: this.tableOptions.itemsPerPage,
      offset: (this.tableOptions.page - 1) * this.tableOptions.itemsPerPage
    }
    const dataFilters = { ...listFilters, ...this.buildDataFilters() }
    this.retrieveStreamList({ ...paginationFilters, ...dataFilters }).then(
      (apiResponse: ApiResponse) => {
        if (!apiResponse.isError && apiResponse.content.status === 200) {
          this.streams = apiResponse.content.body.data
          this.tableTotalQuerySet = apiResponse.content.body.total_queryset
          this.tableIsLoading = false
        } else {
          // TODO: handle error
        }
      })
  }

  showStreamDetailView (uuid: string) {
    this.$router.push({ name: 'Stream Detail', params: { uuid: uuid } })
  }

  mounted () {
    // TODO: Put all (options, search filters) into the state
    this.retrieveWorkflows()
    this.getStreams()
  }
}
