






































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { mapActions } from 'vuex'
import slugify from 'slugify'
import { ApiResponse } from '@/js/api'
import Welcome from '@/components/Welcome.vue'
import {
  SignUpBusinessData,
  SignUpBusinessResponseData
} from '@/js/schemas'

@Component({
  methods: mapActions(['setCSRFToken', 'signUpBusiness']),
  components: {
    Welcome
  }
})
export default class SignUp extends Vue {
  // Data
  loading = false
  valid = true
  showPassword = false
  organizationName = ''
  organizationNameRules = [
    (v: string) => !!v || 'An organization name is required',
    (v: string) =>
      (v && v.length >= 4) || 'Must have a minimum of 4 characters'
  ]

  organizationSlug = ''
  organizationSlugRules = [
    (v: string) => !!v || 'An organization slug is required',
    (v: string) =>
      (v && v.length >= 4) || 'Must have a minimum of 4 characters'
  ]

  email = ''
  emailRules = [
    (v: string) => !!v || 'E-mail is required',
    (v: string) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
  ]

  password = ''
  passwordRules = [
    (v: string) => !!v || 'Password is required',
    (v: string) =>
      (v && v.length >= 6) || 'Password must have a minimum of 6 characters'
  ]

  passwordConfirm = ''
  signUpBusinessSuccess = false
  signUpBusinessResponseData: SignUpBusinessResponseData | any = {}

  // Computed
  get organizationSlugValue () {
    if (this.organizationSlug) {
      return this.organizationSlug
    }
    return slugify(this.organizationName, { lower: true })
  }

  set organizationSlugValue (slug: string) {
    this.organizationSlug = slug
  }

  get passwordConfirmRules () {
    return [
      (v: string) => !!v || 'Password confirmation is required',
      (v: string) =>
        (v && v === this.password) || 'Passwords don\'t match.'
    ]
  }

  get signInURI () {
    return (
      '/sign-in/?type=authid&organization_slug=' +
      `${this.signUpBusinessResponseData.organization_slug}` +
      `&username=${this.signUpBusinessResponseData.username}` +
      `&email=${this.signUpBusinessResponseData.email}`
    )
  }

  // Type mapped methods
  signUpBusiness!: (payload: SignUpBusinessData) => Promise<ApiResponse>;

  signup () {
    // Sign Up as Business
    if (!(this.$refs.signUpBusinessForm as any).validate()) {
      // todo: raise errors
      return
    }
    this.loading = true
    const data: SignUpBusinessData = {
      email: this.email,
      organization_name: this.organizationName,
      organization_slug: this.organizationSlugValue,
      password: this.password
    }
    this.signUpBusiness(data).then((apiResponse) => {
      if (!apiResponse.isError) {
        this.signUpBusinessSuccess = true
        this.signUpBusinessResponseData = apiResponse.content.body
      } else {
        this.signUpBusinessSuccess = false
        // todo: show errors
      }
      this.loading = false
    })
  }
}
