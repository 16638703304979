import { ActionContext } from 'vuex/types'
import { StreamListResource, StreamSpecCreationData } from '@/js/schemas'
import { RootStateData } from '@/store/root'
import { ApiResponse, doApiCall, doEntitiesApiCall, handleApiResponse } from '@/js/api'

interface StreamsStateData {
  streams: StreamListResource[],
  workflowCreationDialogIsOpen: boolean
}

// initial state
const state: StreamsStateData = {
  streams: [],
  workflowCreationDialogIsOpen: false
}

type StreamsContext = ActionContext<StreamsStateData, RootStateData>

// getters
const getters = {}

// actions
const actions = {
  // Stream List
  async retrieveStreamList (
    context: StreamsContext,
    filterParams: Record<string, any>
  ): Promise<ApiResponse> {
    return handleApiResponse(
      await doEntitiesApiCall(
        {
          url: 'streams/',
          method: 'get',
          params: { ...filterParams } // TODO: Remove this
        }
      )
    )
  },
  // Stream Create
  async createStream (
    context: StreamsContext,
    streamSpecCreationData: StreamSpecCreationData
  ): Promise<ApiResponse> {
    return handleApiResponse(
      await doEntitiesApiCall(
        {
          url: 'streams/',
          method: 'post',
          data: streamSpecCreationData
        }
      )
    )
  },
  // Stream Retrieve
  async retrieveStreamDetail (
    context: StreamsContext,
    uuid: string
  ): Promise<ApiResponse> {
    return handleApiResponse(
      await doEntitiesApiCall(
        {
          url: `streams/${uuid}/`,
          method: 'get'
        }
      )
    )
  },
  // Stream Destroy - Cancel
  async cancelStream (
    context: StreamsContext,
    uuid: string
  ): Promise<ApiResponse> {
    return handleApiResponse(
      await doEntitiesApiCall(
        {
          url: `streams/${uuid}/`,
          method: 'delete'
        }
      )
    )
  },
  // Stream Push
  async pushStream (
    context: StreamsContext,
    uuid: string
  ): Promise<ApiResponse> {
    return handleApiResponse(
      await doApiCall(
        {
          url: `push-stream/${uuid}/`,
          method: 'post'
        }
      )
    )
  }

}

// mutations
const mutations = {
  setStreams (state: StreamsStateData, streams: StreamListResource[]) {
    state.streams = streams
  },
  setWorkflowCreationDialogIsOpen (state: StreamsStateData, workflowCreationDialogIsOpen: boolean) {
    state.workflowCreationDialogIsOpen = workflowCreationDialogIsOpen
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
