







































































































import Vue from 'vue'
import { mapActions } from 'vuex'
import Component from 'vue-class-component'
import AppCard from '@/components/AppCard.vue'
import { ApiResponse } from '@/js/api'
import { Workflow } from '@/js/schemas'
import { marked } from 'marked'
import sanitizeHtml from 'sanitize-html'
import { formatDate } from '@/js/utils'

@Component({
  methods: {
    ...mapActions('workflows', ['retrieveWorkflowDetail'])
  },
  components: {
    AppCard
  }
})
export default class WorkflowDetail extends Vue {
  workflowUUID: string = ''
  workflowDetailData: Workflow | any = null

  retrieveWorkflowDetail!: (uuid: string) => Promise<ApiResponse>

  get renderedMarkdown () {
    if (this.workflowDetailData.markdown_doc) {
      return sanitizeHtml(marked.parse(this.workflowDetailData.markdown_doc))
    }
    return '<div class="text-center">There isn\'t documentation about this workflow yet.</div>'
  }

  async copyUUIDToClipboard () {
    await navigator.clipboard.writeText(this.workflowUUID)
  }

  fmtDate (value: string | Date): string {
    return formatDate(value)
  }

  getCubeImageUrl (status: string): string {
    const images = require.context('@/assets/', false, /\.png$/)
    return images(`./cube-${status}.png`)
  }

  showFunctionDetailView (uuid: string) {
    this.$router.push({ name: 'Function Detail', params: { uuid: uuid } })
  }

  mounted () {
    this.workflowUUID = this.$route.params.uuid
    this.retrieveWorkflowDetail(this.workflowUUID).then((apiResponse: ApiResponse) => {
      this.workflowDetailData = apiResponse.content.body
    })
  }
}
