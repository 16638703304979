












































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { ImageRegistryAuthCreationData, ImageRegistryAuth, WorkspaceListResource, ActionCreationData } from '@/js/schemas'
import { ApiResponse } from '@/js/api'
import { Watch } from 'vue-property-decorator'

@Component({
  computed: {
    ...mapState([
      'userWorkspaces',
      'imageRegistriesDialogIsOpen',
      'imageRegistryAuths'
    ]),
    ...mapGetters(['businessData'])
  },
  methods: {
    ...mapActions([
      'retrieveImageRegistryAuthList',
      'updateImageRegistryAuth',
      'deleteImageRegistryAuth',
      'createImageRegistryAuth',
      'createAction'
    ]),
    ...mapMutations([
      'setImageRegistriesDialogIsOpen',
      'setImageRegistryAuths'
    ])
  }
})
export default class ImageRegistriesDialog extends Vue {
  $toast: any
  userWorkspaces!: WorkspaceListResource[]
  imageRegistriesDialogIsOpen!: boolean
  imageRegistryAuths!: ImageRegistryAuth[]
  businessData!: Record<string, string>

  showLoader = true
  selectedListIndex: number = 0
  formIsValid: boolean = false
  showPassword = false
  formRules = {
    hostname: [
      (v: string) => !!v || 'Hostname is required.'
      // (v: string) => (v && /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(v)) || 'Domain name is not valid.'
    ],
    port: [
      (v: string) => !!v || 'Port is required.',
      (v: string) => (v && /^[0-9]+$/.test(v)) || 'Port number is not valid (defaults 443).'
    ],
    user: [
      (v: string) => !!v || 'User is required.'
    ],
    password: [
      (v: string) => !!v || 'Password is required.'
    ]
  }

  formData: ImageRegistryAuthCreationData | null = null
  loadingCreate: boolean = false
  loadingUpdate: boolean = false
  loadingDelete: boolean = false

  retrieveImageRegistryAuthList!: () => Promise<ApiResponse>
  updateImageRegistryAuth!: (payload: ImageRegistryAuth) => Promise<ApiResponse>
  deleteImageRegistryAuth!: (uuid: string) => Promise<ApiResponse>
  createImageRegistryAuth!: (payload: ImageRegistryAuthCreationData) => Promise<ApiResponse>
  createAction!: (payload: ActionCreationData) => Promise<ApiResponse>
  setImageRegistriesDialogIsOpen!: (imageRegistriesDialogIsOpen: boolean) => undefined
  setImageRegistryAuths!: (imageRegistryAuths: ImageRegistryAuth[]) => undefined

  get selectedImageRegistryAuth (): ImageRegistryAuth | null {
    for (let i = 0; i < this.imageRegistryAuths.length; i++) {
      if (this.imageRegistryAuths[i].workspace === this.selectedWorkspace?.uuid) {
        return this.imageRegistryAuths[i]
      }
      if (this.isSelectedMainBusinessRegistry && this.imageRegistryAuths[i].business !== null) {
        return this.imageRegistryAuths[i]
      }
    }
    return null
  }

  get indexOfSelectedImageRegistryAuth (): number | undefined {
    return this.imageRegistryAuths.indexOf(
      (this.selectedImageRegistryAuth as ImageRegistryAuth)
    )
  }

  get selectedWorkspace (): WorkspaceListResource | undefined {
    return this.userWorkspaces[this.selectedListIndex - 1]
  }

  get isSelectedMainBusinessRegistry (): boolean {
    return this.selectedListIndex === 0
  }

  @Watch('imageRegistriesDialogIsOpen')
  watchImageRegistriesDialogIsOpen (_: boolean) {
    this.retrieveImageRegistryAuthList().then((apiResponse) => {
      this.setImageRegistryAuths(apiResponse.content.body.data)
      this.selectedListIndex = 0
      this.resetFormData(0)
      this.showLoader = false
    })
  }

  @Watch('selectedListIndex')
  watchSelectedListIndex (newValue: number) {
    this.formData = null
    if (!newValue) {
      this.selectedListIndex = 0
      this.resetFormData(0)
    } else {
      this.resetFormData(newValue)
    }
  }

  resetFormData (index: number) {
    this.formData = null
    if (index > 0) {
      const selectedWorkspace = this.userWorkspaces[index - 1]
      for (let i = 0; i < this.imageRegistryAuths.length; i++) {
        if (this.imageRegistryAuths[i].workspace === selectedWorkspace.uuid) {
          this.formData = { ...this.imageRegistryAuths[i] }
          break
        }
      }
    }

    if (index === 0) {
      for (let i = 0; i < this.imageRegistryAuths.length; i++) {
        if (this.imageRegistryAuths[i].business !== null) {
          this.formData = { ...this.imageRegistryAuths[i] }
          break
        }
      }
    }
  }

  newFormData () {
    this.formData = Object.assign({}, {
      hostname: '',
      port: 443,
      user: '',
      password: '',
      workspace: (
        !this.isSelectedMainBusinessRegistry
          ? this.userWorkspaces[this.selectedListIndex - 1].uuid
          : null),
      business: (
        this.isSelectedMainBusinessRegistry
          ? this.businessData.UUID
          : null)
    })
  }

  showConfigurationActionInfo (message?: string) {
    if (!message) {
      if (this.isSelectedMainBusinessRegistry) {
        message = 'Configured main business Image Registry Auth.'
      } else {
        message = `Configured Image Registry Auth in workspace ${this.selectedWorkspace?.name}.`
      }
    }
    this.createAction({
      kind: 'update_image_registry_auth',
      level: 'info',
      message: message,
      extra_fields: null
    }).then((_) => {
      this.$toast.info(
        message,
        {
          icon: {
            iconClass: 'v-icon mdi mdi-checkbox-marked lightblue--color',
            iconTag: 'i'
          }
        })
    })
  }

  showConfigurationActionError (message?: string) {
    if (!message) {
      message = `An error occurred on Image Registry Auth configuration.`
    }
    this.createAction({
      kind: 'update_image_registry_auth',
      level: 'error',
      message: message,
      extra_fields: null
    }).then((_) => {
      this.$toast.error(
        message,
        {
          icon: {
            iconClass: 'v-icon mdi mdi-close-box error--color',
            iconTag: 'i'
          }
        })
    })
  }

  handleUpdateImageRegistryAuth () {
    if (!(this.$refs.configureRegistryAuthForm as any).validate()) {
      return
    }
    this.loadingUpdate = true
    this.updateImageRegistryAuth(
      { ...(this.selectedImageRegistryAuth as ImageRegistryAuth), ...this.formData }
    ).then((apiResponse) => {
      if (!apiResponse.isError && apiResponse.content.status === 200) {
        this.imageRegistryAuths[
          (this.indexOfSelectedImageRegistryAuth as number)
        ] = apiResponse.content.body
        this.setImageRegistryAuths(
          [...this.imageRegistryAuths]
        )
        this.showConfigurationActionInfo()
      } else {
        this.showConfigurationActionError()
      }
      this.loadingUpdate = false
    })
  }

  handleCreateImageRegistryAuth () {
    if (!(this.$refs.configureRegistryAuthForm as any).validate()) {
      return
    }
    this.loadingCreate = true
    this.createImageRegistryAuth((
      this.formData as ImageRegistryAuthCreationData)
    ).then((apiResponse) => {
      if (!apiResponse.isError && apiResponse.content.status === 201) {
        this.setImageRegistryAuths([...this.imageRegistryAuths, apiResponse.content.body])
        this.showConfigurationActionInfo()
      } else {
        this.showConfigurationActionError()
      }
      this.loadingCreate = false
    })
  }

  handleDeleteImageRegistryAuth () {
    this.loadingDelete = true
    this.deleteImageRegistryAuth(
      (this.selectedImageRegistryAuth as ImageRegistryAuth).uuid).then(
      (apiResponse) => {
        if (!apiResponse.isError && apiResponse.content.status === 204) {
          this.imageRegistryAuths.splice(
            (this.indexOfSelectedImageRegistryAuth as number),
            1
          )
          this.setImageRegistryAuths(
            [...this.imageRegistryAuths]
          )
          this.formData = null
          let message = ''
          if (this.isSelectedMainBusinessRegistry) {
            message = 'Deleted main business Image Registry Auth.'
          } else {
            message = `Deleted image registry auth configuration in workspace ${this.selectedWorkspace?.name}.`
          }
          this.createAction({
            kind: 'delete_image_registry_auth',
            level: 'warning',
            message: message,
            extra_fields: null
          }).then((_) => {
            this.$toast.warning(
              message,
              {
                icon: {
                  iconClass: 'v-icon mdi mdi-alert warning--color',
                  iconTag: 'i'
                }
              })
          })
        } else {
          const errorMessage = `An error occurred on trying to delete Image Registry Auth configuration.`
          this.showConfigurationActionError(errorMessage)
        }
        this.loadingDelete = false
      })
  }
}
