




















































































import {
  Business,
  SessionAccount,
  UserCreationData,
  UserCreationResponseData
} from '@/js/schemas'
import Vue from 'vue'
import { mapState, mapActions, mapMutations } from 'vuex'
import Component from 'vue-class-component'
import { ApiResponse } from '@/js/api'

@Component({
  computed: {
    ...mapState(['sessionAccount'])
  },
  methods: {
    ...mapActions(
      'configuration',
      ['createUser']
    ),
    ...mapMutations(
      'configuration',
      [
        'setSelectedListIndex',
        'setShowAccountCreateForm'
      ]
    )
  }
})
export default class AccountCreateForm extends Vue {
  sessionAccount!: SessionAccount
  valid = true
  showPassword = false
  username = ''
  usernameRules = [
    (v: string) => !!v || 'Username is required',
    (v: string) =>
      (v && v.length >= 4) || 'Must have a minimum of 4 characters'
    // todo: unicodeUsernameValidator
  ]

  firstName = ''
  firstNameRules = [
    (v: string) => !!v || 'First Name is required',
    (v: string) =>
      (v && v.length >= 2) || 'Must have a minimum of 2 characters'
  ]

  lastName = ''
  lastNameRules = [
    (v: string) => !!v || 'Last Name is required',
    (v: string) =>
      (v && v.length >= 2) || 'Must have a minimum of 4 characters'
  ]

  email = ''
  emailRules = [
    (v: string) => !!v || 'E-mail is required',
    (v: string) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
  ]

  password = ''
  passwordRules = [
    (v: string) => !!v || 'Password is required',
    (v: string) =>
      (v && v.length >= 6) || 'Password must have a minimum of 6 characters'
  ]

  passwordConfirm: string = ''
  role: string = 'developer'

  createUser!: (payload: UserCreationData) => Promise<ApiResponse>
  setSelectedListIndex!: (selectedListIndex: number | null) => undefined
  setShowAccountCreateForm!: (showAccountCreateForm: boolean) => undefined

  get passwordConfirmRules () {
    return [
      (v: string) => !!v || 'Password confirmation is required',
      (v: string) =>
        (v && v === this.password) || 'Passwords don\'t match.'
    ]
  }

  handleCreateUser () {
    if (!(this.$refs.createUserForm as any).validate()) {
      // todo: raise errors
      return
    }
    // this.loading = true
    const data: UserCreationData = {
      role: this.role,
      organization_slug: (this.sessionAccount.business as Business).organization_slug,
      first_name: this.firstName,
      last_name: this.lastName,
      username: this.username,
      email: this.email,
      password: this.password
    }
    this.createUser(data).then((apiResponse) => {
      if (!apiResponse.isError) {
        const userData: UserCreationResponseData = apiResponse.content.body
        this.$toast.info(`A new user account has been created uuid='${userData.account}'`, {
          icon: {
            iconClass: 'v-icon mdi mdi-checkbox-marked lightblue--color',
            iconTag: 'i'
          }
        })
        this.setSelectedListIndex(null)
        this.setSelectedListIndex(0)
        this.setShowAccountCreateForm(false)
      } else {
        this.$toast.error(apiResponse.content.body.detail, {
          icon: {
            iconClass: 'v-icon mdi mdi-alert-circle error--color',
            iconTag: 'i'
          }
        })
      }
      // this.loading = false
    })
  }
}
