













































































































































import Component, { mixins } from 'vue-class-component'
import { mapActions, mapMutations } from 'vuex'
import { SessionDataMixin } from '@/js/utils'
import {
  SignInData,
  SignInResponseData,
  WorkspaceListResource
} from '@/js/schemas'
import { ApiResponse } from '@/js/api'

@Component({
  methods: {
    ...mapActions([
      'signInAuthId',
      'signInEmail',
      'retrieveWorkspaceList'
    ]),
    ...mapMutations([
      'setIsSessionActive',
      'setUserWorkspaces'
    ])
  }
})
export default class SignIn extends mixins(SessionDataMixin) {
  loading = false
  signInAs: number | any = null // 0=auth_id 1=email
  valid = true
  showPassword = false
  organizationSlug = ''
  organizationSlugRules = [
    (v: string) => !!v || 'An organization slug is required',
    (v: string) =>
      (v && v.length >= 4) ||
      'Must have a minimum of 4 characters'
  ]

  username = ''
  usernameRules = [
    (v: string) => !!v || 'Username is required',
    (v: string) =>
      (v && v.length >= 4) ||
      'Must have a minimum of 4 characters'
  ]

  email = ''
  emailRules = [
    (v: string) => !!v || 'E-mail is required',
    (v: string) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
  ]

  password = ''
  passwordRules = [
    (v: string) => !!v || 'Password is required',
    (v: string) =>
      (v && v.length >= 6) ||
      'Password must have a minimum of 6 characters'
  ]

  get userFirstName (): string {
    const firstName = this.sessionAccount?.user?.first_name
    if (firstName) {
      return firstName
    } else {
      return ''
    }
  }

  // Type mapped methods
  signInAuthId!: (payload: SignInData) => Promise<ApiResponse>
  signInEmail!: (payload: SignInData) => Promise<ApiResponse>
  fetchSessionData!: () => Promise<undefined>
  retrieveWorkspaceList!: () => Promise<ApiResponse>
  setIsSessionActive!: (isActive: boolean) => undefined
  setUserWorkspaces!: (workspaces: WorkspaceListResource[]) => undefined

  // Methods
  initStateWithQueryParams () {
    this.signInAs = (this.$route.query.sign_in_as as string) === 'email' ? 1 : 0
    this.organizationSlug = (this.$route.query.organization_slug as string) || ''
    this.username = (this.$route.query.username as string) || ''
    this.email = (this.$route.query.email as string) || ''
  }

  procSignInResponse (apiResponse: ApiResponse) {
    if (!apiResponse.isError) {
      if ((apiResponse.content.body as SignInResponseData).success) {
        // redirect to application dashboard
        // else raise and show an error
        this.fetchSessionData().then(() => {
          this.retrieveWorkspaceList().then((apiResponse) => {
            this.setUserWorkspaces((apiResponse.content.body.data as WorkspaceListResource[]))
            this.setIsSessionActive(true)
            this.$router.push('/dashboard')
          })
        }).then(() => {
          const welcome = this.sessionAccount ? `Welcome ${this.sessionAccount.username}!` : 'Welcome!'
          this.$toast.info(welcome, {
            icon: {
              iconClass: 'v-icon mdi mdi-human-greeting-variant lightblue--color',
              iconTag: 'i'
            }
          })
        })
      } else {
        this.setIsSessionActive(false)
      }
    } else {
      this.$toast.error('Invalid credentials. Try it again!', {
        icon: {
          iconClass: 'v-icon mdi mdi-alert-circle error--color',
          iconTag: 'i'
        }
      })
    }
    this.loading = false
  }

  signin () {
    if (this.signInAs === 0) {
      if (!(this.$refs.signInAuthIdForm as any).validate()) {
        // todo: raise errors
        return
      }
      // Sign In with Auth Id
      this.loading = true
      const data: SignInData = {
        identifier: `${this.organizationSlug}:${this.username}`,
        password: this.password
      }
      this.signInAuthId(data)
        .then((apiResponse) => {
          this.procSignInResponse(apiResponse)
        })
    } else {
      if (!(this.$refs.signInEmailForm as any).validate()) {
        // todo: raise errors
        return
      }
      // Sign In with Email
      this.loading = true
      const data: SignInData = {
        identifier: this.email,
        password: this.password
      }
      this.signInEmail(data)
        .then((apiResponse) => {
          this.procSignInResponse(apiResponse)
        })
    }
  }

  mounted () {
    this.initStateWithQueryParams()
  }
}
