// This module provied all async methods that will handle API calls to Scalade API.

import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError
} from 'axios'

const API_VERSION = 'v1'

const DEV_API_SERVER = {
  PROTOCOL: 'http',
  HOST: 'localhost',
  PORT: 5012
}

const BASE_HEADERS = {
  'Content-Type': 'application/json'
}

let SERVER_HTTP_URL = (
  `${DEV_API_SERVER.PROTOCOL}://` +
  `${DEV_API_SERVER.HOST}:` +
  `${DEV_API_SERVER.PORT}/`
)

let SERVER_WS_URL = (
  `ws://` +
  `${DEV_API_SERVER.HOST}:` +
  `${DEV_API_SERVER.PORT}/`
)

if (process.env.NODE_ENV === 'production') {
  SERVER_HTTP_URL = (
    `${location.protocol}//` +
    `${location.hostname}:` +
    `${location.port}/`
  )
  SERVER_WS_URL = (
    (`${location.protocol}` === 'https:' ? `wss://` : `ws://`) +
    `${location.hostname}:` +
    `${location.port}/`
  )
}

// console.log('NODE_ENV:', process.env.NODE_ENV)
// console.log('SERVER_HTTP_URL:', SERVER_HTTP_URL)
// console.log('SERVER_WS_URL:', SERVER_WS_URL)

axios.defaults.headers = BASE_HEADERS
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'
axios.defaults.withCredentials = true
axios.defaults.baseURL = SERVER_HTTP_URL

export function buildSocketURI (url: string) {
  return `${SERVER_WS_URL}${url}`
}

// Add a response interceptor
axios.interceptors.response.use(function (response) {
  // TODO: Do something with response data
  return response
}, function (error) {
  // Do something with response error
  return Promise.reject(error)
})

interface ResponseContent {
  body: any;
  status: number;
  errorMessage: string;
}

export interface ApiResponse {
  isError: boolean;
  content: Partial<ResponseContent>;
}

export type AxiosErrorResponse = AxiosResponse<any> | AxiosError<any>;

export interface PaginationParams {
  limit: number;
  offset: number;
}

// Makes and axios request given an AxiosRequestConfig
async function makeAxiosRequest (
  config: AxiosRequestConfig
): Promise<AxiosErrorResponse> {
  return axios
    .request(config)
    .then((response: AxiosResponse) => {
      return response
    })
    .catch((error: AxiosError) => {
      return error
    })
}

// Handles an AxiosErrorResponse and returns an ApiResponse object
export function handleApiResponse (
  errResp: AxiosErrorResponse
): ApiResponse {
  if (axios.isAxiosError(errResp)) {
    return {
      isError: true,
      content: {
        body: (errResp as AxiosError).response?.data,
        status: (errResp as AxiosError).response?.status,
        errorMessage: (errResp as AxiosError).message
      }
    }
  } else {
    return {
      isError: false,
      content: {
        body: (errResp as AxiosResponse | any).data,
        status: (errResp as AxiosResponse | any).status
      }
    }
  }
}

// TODO: Refactor this, unify it!
// Base API call
export async function doApiCall (
  config: AxiosRequestConfig | Record<string, any>
): Promise<AxiosErrorResponse> {
  const requestConfig: AxiosRequestConfig = {
    baseURL: SERVER_HTTP_URL + `api/${API_VERSION}/`,
    ...config
  }
  return await makeAxiosRequest(requestConfig)
}

// Auth API calls
export async function doAuthApiCall (
  config: AxiosRequestConfig | Record<string, any>
): Promise<AxiosErrorResponse> {
  const requestConfig: AxiosRequestConfig = {
    baseURL: SERVER_HTTP_URL + `api/${API_VERSION}/auth/`,
    ...config
  }
  return await makeAxiosRequest(requestConfig)
}

// Entities API calls
export async function doEntitiesApiCall (
  config: AxiosRequestConfig | Record<string, any>
): Promise<AxiosErrorResponse> {
  const requestConfig: AxiosRequestConfig = {
    baseURL: SERVER_HTTP_URL + `api/${API_VERSION}/entities/`,
    ...config
  }
  return await makeAxiosRequest(requestConfig)
}

// Get CSRFToken
export async function setCSRFTokenApiCall (): Promise<AxiosErrorResponse> {
  const requestConfig: AxiosRequestConfig = {
    url: 'set-csrftoken/',
    method: 'get'
  }
  return await doApiCall(requestConfig)
}

// Check Active Session
export async function checkActiveSessionApiCall (): Promise<AxiosErrorResponse> {
  const requestConfig: AxiosRequestConfig = {
    url: 'check-session/',
    method: 'get'
  }
  return await doApiCall(requestConfig)
}

// Gets full server version
export async function getVersionApiCall (): Promise<AxiosErrorResponse> {
  const requestConfig: AxiosRequestConfig = {
    url: 'version/',
    method: 'get'
  }
  return await doApiCall(requestConfig)
}

// Gets application config data
export async function getApplicationConfigCall (): Promise<AxiosErrorResponse> {
  const requestConfig: AxiosRequestConfig = {
    url: 'app-config/',
    method: 'get'
  }
  return await doApiCall(requestConfig)
}

// Dashboard API calls
export async function doDashboardApiCall (
  config: AxiosRequestConfig | Record<string, any>
): Promise<AxiosErrorResponse> {
  const requestConfig: AxiosRequestConfig = {
    baseURL: SERVER_HTTP_URL + `api/${API_VERSION}/dashboard/`,
    ...config
  }
  return await makeAxiosRequest(requestConfig)
}
