
















































































































































































































































































































































import Vue from 'vue'
import { mapActions } from 'vuex'
import Component from 'vue-class-component'
import AppCard from '@/components/AppCard.vue'
import JobCube from '@/components/JobCube.vue'
import { Job, Variable, JobMetadata, JobMetadataList } from '@/js/schemas'
import { ApiResponse } from '@/js/api'
import {
  formatDate, groupJobInputs, groupJobOutputs, DataTableOptions,
  getDecodedVariableData, downloadFileObject
} from '@/js/utils'

@Component({
  methods: {
    ...mapActions('jobs', ['retrieveJobDetail', 'retrieveJobMetadata', 'retrieveJobMetadataList'])
  },
  components: {
    AppCard,
    JobCube
  }
})
export default class JobDetail extends Vue {
  jobUUID: string = ''
  showSpecLoader: boolean = true
  jobDetailData: Job | any = null
  jobMetadataData: JobMetadata | any = null
  jobMetadata: Array<JobMetadataList> = []
  tabs: string = 'variables'

  tableHeaders = [
    {
      text: '#',
      align: 'start',
      value: 'index',
      class: 'app-table--header',
      cellClass: 'app-table--cell'
    },
    {
      text: 'Started',
      value: 'started',
      class: 'app-table--header',
      cellClass: 'app-table--cell'
    },
    {
      text: 'Status',
      value: 'transition_status',
      class: 'app-table--header',
      cellClass: 'app-table--cell'
    },
    {
      text: 'Duration',
      value: 'duration',
      class: 'app-table--header',
      cellClass: 'app-table--cell'
    }
  ]

  tableOptions: DataTableOptions = {
    groupBy: [],
    groupDesc: [],
    sortBy: ['index'],
    sortDesc: [true],
    page: 1,
    itemsPerPage: 10,
    multiSort: false,
    mustSort: false
  }

  tableTotalQuerySet: number = 10

  tableIsLoading: boolean = false

  tableFooterProps = {
    'items-per-page-options': [5, 10, 20, 40],
    'items-per-page-text': 'Paginated by:'
  }

  retrieveJobDetail!: (uuid: string) => Promise<ApiResponse>
  retrieveJobMetadata!: (uuid: string) => Promise<ApiResponse>
  retrieveJobMetadataList!: (filterParams: Record<string, any>) => Promise<ApiResponse>

  get showLoader () {
    return !(this.jobDetailData !== null && this.jobMetadataData !== null)
  }

  async copyUUIDToClipboard () {
    await navigator.clipboard.writeText(this.jobUUID)
  }

  async copyImageToClipboard () {
    await navigator.clipboard.writeText(this.jobDetailData.image)
  }

  showStreamDetaiView (uuid: string) {
    this.$router.push({ name: 'Stream Detail', params: { uuid: uuid } })
  }

  showFunctionDetailView (uuid: string) {
    this.$router.push({ name: 'Function Detail', params: { uuid: uuid } })
  }

  fmtDate (value: string | Date | null): string | null {
    return (value !== null ? formatDate(value) : null)
  }

  getGroupedJobInputs () {
    return groupJobInputs(this.jobDetailData)
  }

  getGroupedJobOutputs () {
    return groupJobOutputs(this.jobDetailData)
  }

  getDecodedVariableData (variable: Variable): string {
    return getDecodedVariableData(variable)
  }

  downloadFileObject (variable: Variable) {
    downloadFileObject(variable)
  }

  getJobDetail () {
    this.retrieveJobDetail(this.jobUUID).then((apiResponse: ApiResponse) => {
      this.jobDetailData = apiResponse.content.body
    })
  }

  getJobMetadata () {
    this.retrieveJobMetadata(this.jobUUID).then((apiResponse: ApiResponse) => {
      this.jobMetadataData = apiResponse.content.body
    })
  }

  getJobMetadataList () {
    const jobFilters = {
      job: this.jobUUID
    }
    this.retrieveJobMetadataList(jobFilters).then((apiResponse: ApiResponse) => {
      this.tableIsLoading = true
      this.jobMetadata = apiResponse.content.body.data
      this.tableIsLoading = false
    })
  }

  mounted () {
    // TODO: assert UUID exists, either redirect to a 404 error page.
    this.jobUUID = this.$route.params.uuid
    this.getJobDetail()
    this.getJobMetadata()
    this.getJobMetadataList()
  }
}
