



































































import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { SessionDataMixin } from '@/js/utils'
import { SignUpBusinessResponseData } from '@/js/schemas'

@Component({})
export default class Welcome extends mixins(SessionDataMixin) {
  @Prop()
  businessData!: SignUpBusinessResponseData

  get signInURI () {
    return (
      '/sign-in/?type=authid&organization_slug=' +
      `${this.businessData.organization_slug}` +
      `&username=${this.businessData.username}` +
      `&email=${this.businessData.email}`
    )
  }
}
