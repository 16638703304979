import Vue from 'vue'
import mime from 'mime-types'
import Component from 'vue-class-component'
import { mapState, mapActions, mapMutations } from 'vuex'
import { ApiResponse } from '@/js/api'
import { Workspace, SessionAccount, Job, Variable } from '@/js/schemas'

function padTo2Digits (num: number) {
  return num.toString().padStart(2, '0')
}

export function formatDate (value: string | Date) {
  const date = typeof value === 'string' ? new Date(value) : value
  return (
    [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate())
    ].join('-') +
    ' ' +
    [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes()),
      padTo2Digits(date.getSeconds())
    ].join(':')
  )
}

// 👇️ 2021-10-24 16:21:23 (yyyy-mm-dd hh:mm:ss)
// console.log(formatDate(new Date()))

//  👇️️ 2025-05-04 05:24:07 (yyyy-mm-dd hh:mm:ss)
// console.log(formatDate(new Date('May 04, 2025 05:24:07')))

@Component({
  computed: {
    ...mapState([
      'sessionAccount',
      'sessionWorkspace'
    ])
  },
  methods: {
    ...mapActions([
      'retrieveSessionAccount',
      'retrieveSessionWorkspace'
    ]),
    ...mapMutations([
      'setSessionAccount',
      'setSessionWorkspace'
    ])
  }
})
export class SessionDataMixin extends Vue {
  sessionAccount!: SessionAccount | null
  sessionWorkspace!: Workspace | null

  retrieveSessionAccount!: () => Promise<ApiResponse>
  retrieveSessionWorkspace!: () => Promise<ApiResponse>
  setSessionAccount!: (account: SessionAccount | null) => undefined
  setSessionWorkspace!: (workspace: Workspace | null) => undefined

  async fetchSessionData () {
    this.retrieveSessionAccount().then((apiResponse: ApiResponse) => {
      if (!apiResponse.isError && apiResponse.content.status === 200) {
        this.setSessionAccount(apiResponse.content.body as SessionAccount)
      } else {
        // TODO: notify error
        this.setSessionAccount(null)
      }
    })
    this.retrieveSessionWorkspace().then((apiResponse: ApiResponse) => {
      if (!apiResponse.isError && apiResponse.content.status === 200) {
        this.setSessionWorkspace((apiResponse.content.body as Workspace))
      } else {
        // TODO: notifiy error
        this.setSessionWorkspace(null)
      }
    })
  }
}

export function groupItemsBy (items: Array<any>, groupBy: number = 2) {
  const groupedItems = []
  for (let i = 0; i < items.length; i += groupBy) {
    const elem = items[i]
    if ((i + 1) < items.length) {
      groupedItems.push([elem, items[i + 1]])
    } else if (items.length % 2 === 1) {
      groupedItems.push([elem])
    } else {
      // nothing to do here
    }
  }
  return groupedItems
}

export function groupJobInputs (jobData: Job | null) {
  if (jobData && jobData?.inputs !== null) {
    return groupItemsBy(jobData.inputs, 2)
  } else {
    return []
  }
}

export function groupJobOutputs (jobData: Job | null) {
  if (jobData && jobData?.outputs !== null) {
    return groupItemsBy(jobData.outputs, 2)
  } else {
    return []
  }
}

export interface DataTableOptions {
  groupBy: Array<string>;
  groupDesc: Array<boolean>;
  sortBy: Array<string>;
  sortDesc: Array<boolean>;
  page: number;
  itemsPerPage: number;
  multiSort: boolean;
  mustSort: boolean;
}

export function getVariableTypeEmojiCode (varType: string): string {
  switch (varType) {
    case 'text':
      return '&#128394;'
    case 'number':
      return '&#128290;'
    case 'boolean':
      return '&#x1F6A9;'
    case 'date':
      return '&#128197;'
    case 'file':
      return '&#128203;'
    default:
      return ''
  }
}

function dataURLtoFile (dataurl: string, filename: string): File {
  const arr: Array<string> = dataurl.split(',')
  const mime = (arr[0].match(/:(.*?);/) as RegExpMatchArray)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], filename, { type: mime })
}

export function getDecodedVariableData (variable: Variable): string {
  if (variable.type === 'file') {
    const varDetails = (variable.details as Record<string, string>)
    return `${variable.id_name}.${varDetails.extension}`
  } else {
    return atob(variable.bytes)
  }
}

export function downloadFileObject (variable: Variable) {
  const varDetails = (variable.details as Record<string, string>)
  const mimeType = mime.lookup(`.${varDetails.extension}`)
  const dataURL = `data:${mimeType};base64,${variable.bytes}`
  const file: File = dataURLtoFile(
    dataURL, `${variable.id_name}_${variable.uuid}.${varDetails.extension}`
  )
  const link = document.createElement('a')
  const url = URL.createObjectURL(file)
  link.href = url
  link.download = file.name
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  window.URL.revokeObjectURL(url)
}

export function titleCase (word: string): string {
  return word.replace(/^_*(.)|_+(.)/g, (s, c, d) => c ? c.toUpperCase() : ' ' + d.toUpperCase())
}
