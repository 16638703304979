


























































































































































































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'
import { ApiResponse, PaginationParams } from '@/js/api'
import {
  WorkspaceListResource,
  ActionListResource,
  SessionAccount,
  Workspace
} from '@/js/schemas'
import { formatDate } from '@/js/utils'
import EnvVarDialog from '@/components/EnvVarDialog.vue'
import ImageRegistriesDialog from '@/components/ImageRegistriesDialog.vue'

@Component({
  computed: {
    ...mapState(['sessionAccount', 'userWorkspaces', 'sessionWorkspace']),
    ...mapGetters(['businessData'])
  },
  methods: {
    ...mapActions([
      'signOut',
      'retrieveWorkspaceList',
      'retrieveActionList',
      'changeSessionWorkspace'
    ]),
    ...mapMutations([
      'setIsSessionActive',
      'setUserWorkspaces',
      'setEnvDialogIsOpen',
      'setImageRegistriesDialogIsOpen'
    ])
  },
  components: {
    EnvVarDialog,
    ImageRegistriesDialog
  }
})
export default class TopNavBar extends Vue {
  $router: any
  sessionAccount!: SessionAccount
  sessionWorkspace!: Workspace
  userWorkspaces!: WorkspaceListResource[]
  businessData!: Record<string, string>

  navArgs = {}
  options = [
    {
      text: 'Sign Out',
      icon: 'mdi-account-arrow-left',
      action: 'logout'
    }
  ]

  mainDialog = false

  mainDialogTabs = ['account-detail', 'business-detail', 'actions-detail']
  mainDialogTabSelection = 'account-detail'

  actions: ActionListResource[] | null = null
  actionsPageSize = 5
  actionsCurrentPage = 1

  // Computed
  get workspaceNames (): string[] {
    return this.userWorkspaces.map((item: WorkspaceListResource) => item.name)
  }

  get userData (): Record<string, string | null> {
    if (this.sessionAccount.user) {
      return {
        'first name': this.sessionAccount.user.first_name,
        'last name': this.sessionAccount.user.last_name
      }
    }
    return {}
  }

  get accountData (): Record<string, any> {
    const [organizationSlug, username] = this.sessionAccount.auth_id.split(':')
    return {
      UUID: this.sessionAccount.uuid,
      'organization slug': organizationSlug,
      username: username,
      email: this.sessionAccount.email,
      ...this.userData,
      'is business': this.sessionAccount.is_business ? 'yes' : 'no',
      'date joined': formatDate(this.sessionAccount.date_joined),
      'last login': this.sessionAccount.last_login !== null ? formatDate(this.sessionAccount.last_login) : null,
      groups: this.sessionAccount.groups,
      workspaces: this.sessionAccount.workspaces.map((value) => value.uuid)
    }
  }

  // Type mapped methods
  signOut!: () => Promise<ApiResponse>
  retrieveWorkspaceList!: () => Promise<ApiResponse>
  retrieveActionList!: (
    paginationParams: PaginationParams
  ) => Promise<ApiResponse>

  setIsSessionActive!: (isActive: boolean) => undefined
  setUserWorkspaces!: (workspaces: WorkspaceListResource[]) => undefined
  setEnvDialogIsOpen!: (envDialogIsOpen: boolean) => undefined
  setImageRegistriesDialogIsOpen!: (imageRegistriesDialogIsOpen: boolean) => undefined
  changeSessionWorkspace!: (workspaceUUID: string) => Promise<ApiResponse>

  @Watch('mainDialogTabSelection')
  watchMainSelectedDialogTab (newValue: string) {
    this.actions = null
    this.actionsCurrentPage = 1
    if (newValue === 'actions-detail') {
      this.fetchActions()
    }
  }

  @Watch('actionsCurrentPage')
  watchActionsCurrentPage (_: number) {
    this.actions = null
    this.fetchActions()
  }

  fmtDate (value: string | Date): string {
    return formatDate(value)
  }

  logout () {
    this.signOut().then((apiResponse) => {
      if (!apiResponse.isError && apiResponse.content.status === 200) {
        this.setIsSessionActive(false)
        // TODO: Reset all Vuex store
        this.$router.push('/sign-in')
      } else {
        // todo: handle logout error
      }
    })
  }

  handleWorkspaceSelectorClick () {
    this.handleWorkspaceList()
  }

  handleWorkspaceChange (wsName: string) {
    const wsUUID = this.userWorkspaces.filter(
      (ws) => ws.name === wsName
    )[0].uuid
    this.changeSessionWorkspace(wsUUID).then(() => {
      this.$toast.info(`Session workspace changed to '${wsName}'`, {
        icon: {
          iconClass: 'v-icon mdi mdi-checkbox-marked lightblue--color',
          iconTag: 'i'
        }
      })
      location.reload()
    })
  }

  handleWorkspaceList () {
    this.retrieveWorkspaceList().then((apiResponse: ApiResponse) => {
      this.setUserWorkspaces(
        apiResponse.content.body.data as WorkspaceListResource[]
      )
    })
  }

  fetchActions () {
    this.retrieveActionList({
      limit: this.actionsPageSize,
      offset: Math.max(0, (this.actionsCurrentPage - 1) * this.actionsPageSize)
    }).then((apiResponse) => {
      this.actions = apiResponse.content.body.data as ActionListResource[]
    })
  }
}
