
import { ActionContext } from 'vuex/types'
import {
  ApiResponse,
  checkActiveSessionApiCall,
  doAuthApiCall,
  doEntitiesApiCall,
  getVersionApiCall,
  getApplicationConfigCall,
  handleApiResponse,
  PaginationParams,
  setCSRFTokenApiCall
} from '@/js/api'
import {
  SignInData,
  SignUpBusinessData,
  WorkspaceListResource,
  SessionAccount,
  ResetPasswordData,
  Workspace,
  EnvVar,
  EnvVarCreationData,
  ActionCreationData,
  ImageRegistryAuth,
  ImageRegistryAuthCreationData,
  Business,
  User
} from '@/js/schemas'

export interface RootStateData {
  isSessionActive: boolean | null,
  sessionAccount: SessionAccount | null,
  sessionWorkspace: Workspace | null,
  serverVersion: string,
  appConfigData: any,
  sidebarCollapsed: boolean,
  userWorkspaces: WorkspaceListResource[],
  envDialogIsOpen: boolean,
  imageRegistriesDialogIsOpen: boolean,
  workspaceEnvVars: EnvVar[],
  imageRegistryAuths: ImageRegistryAuth[]
}

// initial state
const state: RootStateData = {
  isSessionActive: null,
  sessionAccount: null,
  sessionWorkspace: null,
  serverVersion: '',
  appConfigData: {},
  sidebarCollapsed: true,
  userWorkspaces: [],
  envDialogIsOpen: false,
  imageRegistriesDialogIsOpen: false,
  workspaceEnvVars: [],
  imageRegistryAuths: []
}

type RootContext = ActionContext<RootStateData, RootStateData>

// getters
const getters = {
  businessData: (state: RootStateData, getters: any) => {
    const account: SessionAccount = (state.sessionAccount as SessionAccount)
    let business: Business
    if (account.is_business) {
      business = account.business as Business
    } else {
      business = (account.user as User).business as Business
    }
    return {
      UUID: business.uuid,
      'organization name': business.organization_name,
      'organization slug': business.organization_slug
    }
  }
}

// actions
const actions = {
  // Obtains a new CSRFToken
  async setCSRFToken (): Promise<ApiResponse> {
    return handleApiResponse(await setCSRFTokenApiCall())
  },

  // Checks active Session (if user is authenticated)
  async checkActiveSession (): Promise<ApiResponse> {
    return handleApiResponse(await checkActiveSessionApiCall())
  },

  // Gets full server version
  async getServerVersion (): Promise<ApiResponse> {
    return handleApiResponse(await getVersionApiCall())
  },

  // Gets App config
  async getApplicationConfig (): Promise<ApiResponse> {
    return handleApiResponse(await getApplicationConfigCall())
  },

  // Auth: Sing Up new Business
  async signUpBusiness (
    context: RootContext,
    payload: SignUpBusinessData | any
  ): Promise<ApiResponse> {
    return handleApiResponse(
      await doAuthApiCall(
        {
          url: 'signup-business/',
          method: 'post',
          data: payload
        }
      )
    )
  },

  // Auth: Sign In with Auth ID
  async signInAuthId (
    context: RootContext,
    payload: SignInData | any
  ): Promise<ApiResponse> {
    return handleApiResponse(
      await doAuthApiCall(
        {
          url: 'signin-authid/',
          method: 'post',
          data: payload
        }
      )
    )
  },

  // Auth: Sign In with Email
  async signInEmail (
    context: RootContext,
    payload: SignInData | any
  ): Promise<ApiResponse> {
    return handleApiResponse(
      await doAuthApiCall(
        {
          url: 'signin-email/',
          method: 'post',
          data: payload
        }
      )
    )
  },

  // Auth: Sign Out
  async signOut (
    context: RootContext
  ): Promise<ApiResponse> {
    return handleApiResponse(
      await doAuthApiCall(
        {
          url: 'signout/',
          method: 'post',
          data: null
        }
      )
    )
  },

  // Generate a Reset Password Token
  async generateResetPasswordToken (
    context: RootContext,
    payload: any
  ): Promise<ApiResponse> {
    return handleApiResponse(
      await doAuthApiCall(
        {
          url: 'generate-reset-password-token/',
          method: 'post',
          data: payload
        }
      )
    )
  },

  // Reset Password
  async resetPassword (
    context: RootContext,
    payload: ResetPasswordData
  ): Promise<ApiResponse> {
    return handleApiResponse(
      await doAuthApiCall(
        {
          url: 'reset-password/',
          method: 'post',
          data: payload
        }
      )
    )
  },

  // Retrieve session workspace
  async retrieveSessionAccount (
    context: RootContext
  ): Promise<ApiResponse> {
    return handleApiResponse(
      await doAuthApiCall({
        url: 'session-account/',
        method: 'get'
      })
    )
  },

  // Retrieve session workspace
  async retrieveSessionWorkspace (
    context: RootContext
  ): Promise<ApiResponse> {
    return handleApiResponse(
      await doAuthApiCall({
        url: 'session-workspace/',
        method: 'get'
      })
    )
  },

  // Change Session Workspace
  async changeSessionWorkspace (
    context: RootContext,
    workspaceUUID: string
  ): Promise<ApiResponse> {
    const apiResponse = handleApiResponse(
      await doAuthApiCall({
        url: `change-session-workspace/${workspaceUUID}/`,
        method: 'post'
      })
    )

    const isSuccess = apiResponse.content.body.success
    if (isSuccess) {
      await context.dispatch('retrieveSessionWorkspace').then(
        (apiResponse) => context.commit(
          'setSessionWorkspace',
          apiResponse.content.body as Workspace
        )
      )
    } else {
      // TODO: Handle error
    }
    return apiResponse
  },

  // List user workspaces
  async retrieveWorkspaceList (
    context: RootContext
  ): Promise<ApiResponse> {
    return handleApiResponse(
      await doEntitiesApiCall(
        {
          url: 'workspaces/?limit=1000',
          method: 'get'
        }
      )
    )
  },

  // List user actions
  async retrieveActionList (
    context: RootContext,
    paginationParams: PaginationParams
  ): Promise<ApiResponse> {
    return handleApiResponse(
      await doEntitiesApiCall(
        {
          url: 'actions/',
          method: 'get',
          params: { ...paginationParams }
        }
      )
    )
  },

  // Create a user action
  async createAction (
    context: RootContext,
    payload: ActionCreationData
  ): Promise<ApiResponse> {
    return handleApiResponse(
      await doEntitiesApiCall(
        {
          url: 'actions/',
          method: 'post',
          data: payload
        }
      )
    )
  },

  // List workspace Environment Varaibles
  async retrieveEnvVarList (
    context: RootContext
  ): Promise<ApiResponse> {
    return handleApiResponse(
      await doEntitiesApiCall(
        {
          url: 'env-vars/',
          method: 'get',
          params: { limit: 1000 }
        }
      )
    )
  },

  // Update a workspace Environment Variable
  async updateEnvVar (
    context: RootContext,
    payload: EnvVar
  ): Promise<ApiResponse> {
    return handleApiResponse(
      await doEntitiesApiCall(
        {
          url: `env-vars/${payload.uuid}/`,
          method: 'put',
          data: payload
        }
      )
    )
  },

  // Delete a workspace Environment Variable
  async deleteEnvVar (
    context: RootContext,
    uuid: string
  ): Promise<ApiResponse> {
    return handleApiResponse(
      await doEntitiesApiCall(
        {
          url: `env-vars/${uuid}/`,
          method: 'delete'
        }
      )
    )
  },

  // Create a workspace Environment Variable
  async createEnvVar (
    context: RootContext,
    payload: EnvVarCreationData
  ): Promise<ApiResponse> {
    return handleApiResponse(
      await doEntitiesApiCall(
        {
          url: 'env-vars/',
          method: 'post',
          data: payload
        }
      )
    )
  },

  // List workspace Image Registry Auth
  async retrieveImageRegistryAuthList (
    context: RootContext
  ): Promise<ApiResponse> {
    return handleApiResponse(
      await doEntitiesApiCall(
        {
          url: 'image-registry-auths/',
          method: 'get',
          params: { limit: 1000 }
        }
      )
    )
  },

  // Update a workspace Image Registry Auth
  async updateImageRegistryAuth (
    context: RootContext,
    payload: ImageRegistryAuth
  ): Promise<ApiResponse> {
    return handleApiResponse(
      await doEntitiesApiCall(
        {
          url: `image-registry-auths/${payload.uuid}/`,
          method: 'put',
          data: payload
        }
      )
    )
  },

  // Delete a workspace Image Registry Auth
  async deleteImageRegistryAuth (
    context: RootContext,
    uuid: string
  ): Promise<ApiResponse> {
    return handleApiResponse(
      await doEntitiesApiCall(
        {
          url: `image-registry-auths/${uuid}/`,
          method: 'delete'
        }
      )
    )
  },

  // Create a workspace Image Registry Auth
  async createImageRegistryAuth (
    context: RootContext,
    payload: ImageRegistryAuthCreationData
  ): Promise<ApiResponse> {
    return handleApiResponse(
      await doEntitiesApiCall(
        {
          url: 'image-registry-auths/',
          method: 'post',
          data: payload
        }
      )
    )
  }

}

// mutations
const mutations = {
  setIsSessionActive (state: RootStateData, isActive: boolean | null) {
    state.isSessionActive = isActive
  },
  setSessionAccount (state: RootStateData, account: SessionAccount | null) {
    state.sessionAccount = account
  },
  setSessionWorkspace (state: RootStateData, workspace: Workspace | null) {
    state.sessionWorkspace = workspace
  },
  setServerVersion (state: RootStateData, serverVersion: string) {
    state.serverVersion = serverVersion
  },
  setAppConfigData (state: RootStateData, appConfigData: any) {
    state.appConfigData = appConfigData
  },
  setSidebarCollapsed (state: RootStateData, sidebarCollapsed: boolean) {
    state.sidebarCollapsed = sidebarCollapsed
  },
  setUserWorkspaces (state: RootStateData, userWorkspaces: WorkspaceListResource[]) {
    state.userWorkspaces = userWorkspaces
  },
  setEnvDialogIsOpen (state: RootStateData, envDialogIsOpen: boolean) {
    state.envDialogIsOpen = envDialogIsOpen
  },
  setImageRegistriesDialogIsOpen (state: RootStateData, imageRegistriesDialogIsOpen: boolean) {
    state.imageRegistriesDialogIsOpen = imageRegistriesDialogIsOpen
  },
  setWorkspaceEnvVars (state: RootStateData, workspaceEnvVars: EnvVar[]) {
    state.workspaceEnvVars = workspaceEnvVars
  },
  setImageRegistryAuths (state: RootStateData, imageRegistryAuths: ImageRegistryAuth[]) {
    state.imageRegistryAuths = imageRegistryAuths
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
