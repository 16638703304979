






































































import Vue from 'vue'
import { mapActions } from 'vuex'
import Component from 'vue-class-component'
import AppCard from '@/components/AppCard.vue'
import FunctionSpec from '@/components/FunctionSpec.vue'
import { Function } from '@/js/schemas'
import { ApiResponse } from '@/js/api'

@Component({
  methods: {
    ...mapActions('functions', ['retrieveFunctionDetail'])
  },
  components: {
    AppCard,
    FunctionSpec
  }
})
export default class FunctionDetail extends Vue {
  functionUUID: string = ''
  showSpecLoader: boolean = true
  functionDetailData: Function | any = null

  retrieveFunctionDetail!: (uuid: string) => Promise<ApiResponse>

  async copyUUIDToClipboard () {
    await navigator.clipboard.writeText(this.functionUUID)
  }

  async copyImageToClipboard () {
    await navigator.clipboard.writeText(this.functionDetailData.image)
  }

  mounted () {
    // TODO: assert UUID exists, either redirect to a 404 error page.
    this.functionUUID = this.$route.params.uuid
    this.retrieveFunctionDetail(this.functionUUID).then((apiResponse: ApiResponse) => {
      this.functionDetailData = apiResponse.content.body
    })
  }
}
