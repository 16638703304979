import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
// import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#0288D1',
        info: '#0288D1',
        // secondary: colors.red.lighten4,
        // accent: colors.indigo.base
        error: '#e60000'
      }
    }
  }
})
