



















































import Vue from 'vue'
import Component from 'vue-class-component'
import { Job } from '@/js/schemas'
import { Prop } from 'vue-property-decorator'

@Component({})
export default class JobCube extends Vue {
  @Prop()
  jobData!: Job

  getCubeImageUrl (status: string): string {
    const images = require.context('@/assets/', false, /\.png$/)
    return images(`./cube-${status}.png`)
  }

  showJobDetailView (uuid: string) {
    this.$router.push({ name: 'Job Detail', params: { uuid: uuid } })
  }
}
