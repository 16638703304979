




















































































































import { ApiResponse } from '@/js/api'
import { WorkflowCreationData, Workflow, ActionCreationData } from '@/js/schemas'
import { marked } from 'marked'
import sanitizeHtml from 'sanitize-html'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { mapActions, mapMutations, mapState } from 'vuex'

@Component({
  computed: {
    ...mapState('streams', [
      'workflowCreationDialogIsOpen'
    ])
  },
  methods: {
    ...mapActions('workflows', [
      'createWorkflow'
    ]),
    ...mapActions(['createAction']),
    ...mapMutations('streams', [
      'setWorkflowCreationDialogIsOpen'
    ])
  }
})
export default class WorkflowCreationDialog extends Vue {
  @Prop()
  streamTemplate!: Record<string, any>

  formData = {
    configName: '',
    description: '',
    markdownDoc: '',
    pinnedVersions: false
  }

  formRules = {
    configName: [
      (v: string) => !!v || 'Config Name is required.',
      (v: string) => v.length > 6 || 'Config Name must have a minimum of 6 characters.'
    ],
    description: [
      (v: string) => !!v || 'Description is required.',
      (v: string) => v.length > 12 || 'Description must be verbosee and have a minimum of 12 characters.'
    ],
    markdownDoc: [],
    pinnedVersions: []
  }

  showMarkdownNavDrawer: boolean = false
  loadingCreate: boolean = false

  workflowCreationDialogIsOpen!: boolean

  createWorkflow!: (workflowCreationData: WorkflowCreationData) => Promise<ApiResponse>
  createAction!: (payload: ActionCreationData) => Promise<ApiResponse>
  setWorkflowCreationDialogIsOpen!: (workflowCreationDialogIsOpen: boolean) => undefined

  get renderedMarkdown () {
    if (this.formData.markdownDoc !== '') {
      return sanitizeHtml(marked.parse(this.formData.markdownDoc))
    }
    return null
  }

  handleCreateWorkflow () {
    if (!(this.$refs.createWorkflowForm as any).validate()) {
      return
    }
    this.loadingCreate = true
    const creationData: WorkflowCreationData = {
      config_name: this.formData.configName,
      template: this.streamTemplate,
      description: this.formData.description,
      markdown_doc: this.formData.markdownDoc !== '' ? this.formData.markdownDoc : null,
      pinned_versions: this.formData.pinnedVersions

    }
    this.createWorkflow(creationData).then((apiResponse) => {
      if (!apiResponse.isError && apiResponse.content.status === 201) {
        const workflowUUID = (apiResponse.content.body as Workflow).uuid
        const message = `Workflow ${workflowUUID} has been created.`
        this.createAction({
          kind: 'create_workflow',
          level: 'info',
          message: message,
          extra_fields: null
        }).then((_) => {
          this.$toast.info(message, {
            icon: {
              iconClass: 'v-icon mdi mdi-checkbox-marked lightblue--color',
              iconTag: 'i'
            }
          })
          this.loadingCreate = false
          this.setWorkflowCreationDialogIsOpen(false)
          this.$router.push({
            name: 'Workflow Detail',
            params: { uuid: workflowUUID }
          })
        })
      } else {
        const errorDetail = JSON.stringify(apiResponse.content.body)
        this.createAction({
          kind: 'create_workflow',
          level: 'error',
          message: errorDetail,
          extra_fields: { parse_as: 'json' }
        }).then((_) => {
          this.$toast.error(errorDetail, {
            icon: {
              iconClass: 'v-icon mdi mdi-close-box error--color',
              iconTag: 'i'
            }
          })
        })
        this.loadingCreate = false
      }
    })
  }
}
