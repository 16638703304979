

























































import Vue from 'vue'
import Component from 'vue-class-component'
import { mapState, mapActions } from 'vuex'
import yaml from 'yaml'
import AppCard from '@/components/AppCard.vue'
import YamlEditor from '@/components/YamlEditor.vue'
import FunctionSpec from '@/components/FunctionSpec.vue'
import {
  FunctionCreationData,
  Function,
  FunctionSpecCreationData,
  ActionCreationData,
  Workspace
} from '@/js/schemas'
import { ApiResponse } from '@/js/api'

@Component({
  computed: {
    ...mapState(['sessionWorkspace']),
    ...mapState('functions', ['yamlCodeString'])
  },
  methods: {
    ...mapActions(['createAction']),
    ...mapActions('functions', ['createFunction'])
  },
  components: {
    AppCard,
    YamlEditor,
    FunctionSpec
  }
})
export default class FunctionCreate extends Vue {
  sessionWorkspace!: Workspace
  yamlCodeString!: string
  loadingCreate: boolean = false

  createAction!: (payload: ActionCreationData) => Promise<ApiResponse>

  createFunction!: (
    functionCreationData: FunctionSpecCreationData
  ) => Promise<ApiResponse>

  get functionCreationData (): FunctionCreationData | null {
    if (this.yamlCodeString) {
      return yaml.parse(this.yamlCodeString)
    }
    return null
  }

  handleCreateFunction () {
    this.loadingCreate = true
    const creationData: FunctionSpecCreationData = {
      spec: this.functionCreationData as FunctionCreationData,
      metadata: {
        workspace: this.sessionWorkspace.name
      }
    }
    this.createFunction(creationData).then((apiResponse) => {
      if (!apiResponse.isError && apiResponse.content.status === 201) {
        const functionUUID = (apiResponse.content.body as Function).uuid
        const message = `Function ${functionUUID} has been created.`
        this.createAction({
          kind: 'create_function',
          level: 'info',
          message: message,
          extra_fields: null
        }).then((_) => {
          this.$toast.info(message, {
            icon: {
              iconClass: 'v-icon mdi mdi-checkbox-marked lightblue--color',
              iconTag: 'i'
            }
          })
          this.$router.push({
            name: 'Function Detail',
            params: { uuid: functionUUID }
          })
        })
        this.loadingCreate = false
      } else {
        const errorDetail = JSON.stringify(apiResponse.content.body)
        this.createAction({
          kind: 'create_function',
          level: 'error',
          message: errorDetail,
          extra_fields: { parse_as: 'json' }
        }).then((_) => {
          this.$toast.error(errorDetail, {
            icon: {
              iconClass: 'v-icon mdi mdi-close-box error--color',
              iconTag: 'i'
            }
          })
        })
        this.loadingCreate = false
      }
    })
  }
}
