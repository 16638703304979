



































































































































































































































































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { mapState, mapActions } from 'vuex'
import AppCard from '@/components/AppCard.vue'
import {
  InputData,
  Function,
  Workspace,
  StreamSpecCreationData,
  JobCreationData,
  InputEncoded,
  Stream,
  ActionCreationData,
  WorkflowListResource,
  Workflow,
  IOBindingCreationSpec
} from '@/js/schemas'
import { ApiResponse } from '@/js/api'
import { groupItemsBy, getVariableTypeEmojiCode } from '@/js/utils'

interface InputCreationData {
  id_name: string;
  value: string | File | null;
}

@Component({
  computed: {
    ...mapState(['sessionWorkspace'])
  },
  methods: {
    ...mapActions(['createAction']),
    ...mapActions('functions', ['retrieveFunctionDetail']),
    ...mapActions('streams', ['createStream']),
    ...mapActions('workflows', ['retrieveWorkflowList', 'retrieveWorkflowDetail'])
  },
  components: {
    AppCard
  }
})
export default class StreamCreate extends Vue {
  showLoader: boolean = true
  sessionWorkspace!: Workspace
  loadingCreate: boolean = false
  streamName: string = ''
  currentJob: number = 1
  totalJobs: number = 1
  functionTypes: Array<string> = ['']
  functionSpecs: Array<Function | null> = [null]
  functionInputs: Array<Array<InputCreationData> | null> = [null]

  verifiedIcons: Array<string> = ['mdi-checkbox-blank-outline']
  verifyIsLoading: Array<boolean> = [false]

  workflows: Record<'uuid' | 'config_name', string>[] = []
  selectedConfigName: string = '# blank'
  workflowDetailData: Workflow | any = null
  discardTotalJobsUpdate: boolean = false

  bindingDialogIsOpen: boolean = false
  selectedBindingJobIndex: number | null = null
  selectedBindingIdName: string | null = null
  selectedBindingInputType: string | null = null

  selectedOutputIndex: number | null = null

  IOBindingCreationData: Record<string, IOBindingCreationSpec[]> = {}

  createAction!: (payload: ActionCreationData) => Promise<ApiResponse>
  retrieveFunctionDetail!: (uuid: string) => Promise<ApiResponse>
  createStream!: (streamSpecCreationData: StreamSpecCreationData) => Promise<ApiResponse>
  retrieveWorkflowList!: (filterParams: Record<string, any>) => Promise<ApiResponse>
  retrieveWorkflowDetail!: (uuid: string) => Promise<ApiResponse>

  @Watch('totalJobs')
  watchTotalJobs (currentValue: number, prevValue: number) {
    if (!this.discardTotalJobsUpdate) {
      if (this.currentJob > currentValue) {
        this.currentJob = currentValue
      }

      if (currentValue < prevValue) {
        this.functionTypes.pop()
        this.functionSpecs.pop()
        this.functionInputs.pop()
        this.verifiedIcons.pop()
        this.verifyIsLoading.pop()
      } else {
        this.functionTypes.push('')
        this.functionSpecs.push(null)
        this.functionInputs.push(null)
        this.verifiedIcons.push('mdi-checkbox-blank-outline')
        this.verifyIsLoading.push(false)
        // this.currentJob = currentValue
      }
    } else {
      this.discardTotalJobsUpdate = false
    }
  }

  get configNames (): string[] {
    return [
      '# blank',
      ...this.workflows.map((value: Record<'uuid' | 'config_name', string>) => value.config_name)
    ]
  }

  get linkableOutputsList (): Record<'jobIndex' | 'outputIdName' | 'outputVerboseName', number | string>[] {
    const currentJobIndex = this.currentJob - 1
    const linkableOutputs: any[] = []
    for (let i = 0; i < currentJobIndex; i++) {
      const funcSpec = this.functionSpecs[i]
      if (funcSpec?.outputs) {
        const outputs = funcSpec.outputs
        linkableOutputs.push(
          ...outputs.filter(outVar => outVar.type === this.selectedBindingInputType).map(
            outVar => {
              return {
                jobIndex: i + 1,
                outputIdName: outVar.id_name,
                outputVerboseName: outVar.verbose_name
              }
            }
          )
        )
      }
    }
    return linkableOutputs
  }

  showEmoji (varType: string) {
    return getVariableTypeEmojiCode(varType)
  }

  nextJob (n: number) {
    if (n === this.totalJobs) {
      this.currentJob = 1
    } else {
      this.currentJob = n + 1
    }
  }

  addNewJob () {
    // maximum total Jobs = 8
    if (this.totalJobs < 8) {
      this.totalJobs += 1
      // this.currentJob = this.totalJobs
    }
  }

  undoLastJob () {
    if (this.totalJobs > 1) {
      this.totalJobs -= 1
    }
  }

  isFunctionVerified (index: number): boolean {
    if (this.verifiedIcons[index] === 'mdi-checkbox-marked') {
      return true
    } else {
      return false
    }
  }

  openIOBindingDialog (jobIndex: number, inputIdName: string, inputType: string) {
    this.selectedBindingIdName = inputIdName
    this.selectedBindingJobIndex = jobIndex
    this.selectedBindingInputType = inputType
    this.bindingDialogIsOpen = true
  }

  handleCreateIOBinding () {
    const jobIndexKey: string = `${(this.selectedBindingJobIndex as number)}`
    if (!(jobIndexKey in this.IOBindingCreationData)) {
      this.IOBindingCreationData[jobIndexKey] = []
    }
    const bindings = this.IOBindingCreationData[jobIndexKey]
    bindings.push({
      inputIdName: (this.selectedBindingIdName as string),
      outputJobIndex: (this.linkableOutputsList[
        (this.selectedOutputIndex as number)
      ].jobIndex as number),
      outputIdName: (this.linkableOutputsList[
        (this.selectedOutputIndex as number)
      ].outputIdName as string)
    })
    this.functionSpecs = [...this.functionSpecs]
    this.bindingDialogIsOpen = false
  }

  handleDeleteIOBinding (jobIndex: number, inputIdName: string) {
    const key = `${jobIndex}`
    if (key in this.IOBindingCreationData) {
      const bindings = this.IOBindingCreationData[key]
      for (let i = 0; i < bindings.length; i++) {
        const bind = bindings[i]
        if (bind.inputIdName === inputIdName) {
          bindings.splice(i, 1)
          this.IOBindingCreationData[key] = [...bindings]
        }
      }
    }
    this.IOBindingCreationData = { ...this.IOBindingCreationData }
    this.functionSpecs = [...this.functionSpecs]
    this.bindingDialogIsOpen = false
  }

  getIOBinding (
    jobIndex: number,
    inputIdName: string,
    returnType: string = 'string'
  ): string | object | null {
    const key = `${jobIndex}`
    if (key in this.IOBindingCreationData) {
      const bindings = this.IOBindingCreationData[key]
      for (let i = 0; i < bindings.length; i++) {
        const bind = bindings[i]
        if (bind.inputIdName === inputIdName) {
          if (returnType === 'object') {
            return {
              job_index: bind.outputJobIndex,
              id_name: bind.outputIdName
            }
          }
          return `#${bind.outputJobIndex} ${bind.outputIdName}`
        }
      }
    }
    return null
  }

  resetCreationState () {
    this.discardTotalJobsUpdate = true
    this.currentJob = 1
    this.totalJobs = 1
    this.functionTypes = ['']
    this.functionSpecs = [null]
    this.functionInputs = [null]
    this.verifiedIcons = ['mdi-checkbox-blank-outline']
    this.verifyIsLoading = [false]
  }

  initializeFunctionInputs (index: number): Array<InputCreationData> | null {
    const spec = this.functionSpecs[index]
    const inputs = []
    if (spec !== null && spec.inputs !== null) {
      for (let i = 0; i < spec.inputs.length; i++) {
        inputs.push({ id_name: spec.inputs[i].id_name, value: null })
      }
      return inputs
    } else {
      return null
    }
  }

  verifyFunction (index: number) {
    if (!this.isFunctionVerified(index)) {
      this.verifyIsLoading[index] = true
      this.verifyIsLoading = [...this.verifyIsLoading]
      const uuid = this.functionTypes[index]
      this.retrieveFunctionDetail(uuid).then((apiResponse: ApiResponse) => {
        if (!apiResponse.isError && apiResponse.content.status === 200) {
          this.functionSpecs[index] = apiResponse.content.body
          this.verifiedIcons[index] = 'mdi-checkbox-marked'
          this.functionInputs[index] = this.initializeFunctionInputs(index)
        } else {
          this.verifiedIcons[index] = 'mdi-close-box'
        }
        this.functionSpecs = [...this.functionSpecs]
        this.verifiedIcons = [...this.verifiedIcons]
        this.functionInputs = [...this.functionInputs]
        this.verifyIsLoading[index] = false
        this.verifyIsLoading = [...this.verifyIsLoading]
      })
    }
  }

  getFunctionKeyName (index: number): string {
    const spec = this.functionSpecs[index]
    if (spec !== null) {
      return `${spec.key} v${spec.version} - ${spec.verbose_name}`
    } else {
      return ''
    }
  }

  getGroupedFunctionInputs (index: number): Array<Array<InputData>> {
    const spec = this.functionSpecs[index]
    if (spec !== null && spec.inputs !== null) {
      return groupItemsBy(spec.inputs, 2)
    } else {
      return []
    }
  }

  async convertFileToBase64 (file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(
        (reader.result as string).replace('data:', '').replace(/^.+,/, '')
      )
      reader.onerror = error => reject(error)
    })
  }

  async inputValueToBase64 (value: string | File): Promise<string> {
    if (typeof value === 'string') {
      return btoa(value)
    } else {
      return await this.convertFileToBase64(value)
    }
  }

  async getEncodedInputData (
    jobIndex: number,
    data: Array<InputCreationData> | null
  ): Promise<Array<InputEncoded>> {
    if (data === null) {
      return []
    } else {
      const encodedInputs: Array<InputEncoded> = []
      for (let i = 0; i < data.length; i++) {
        const item = data[i]
        let bytes = null
        if (item.value) {
          bytes = await this.inputValueToBase64((item.value as string | File))
        }
        const inputData: Record<'id_name' | 'bytes' | 'binding', string | null> | any = {
          id_name: item.id_name,
          bytes: bytes
        }
        const binding: object | any = this.getIOBinding(jobIndex, item.id_name, 'object')
        if (binding) {
          inputData.binding = {
            job_index: binding.job_index - 1,
            id_name: binding.id_name
          }
        }
        encodedInputs.push(inputData)
      }
      return encodedInputs
    }
  }

  async buildJobsCreationData (): Promise<Array<JobCreationData>> {
    const data = []
    for (let i = 0; i < this.totalJobs; i++) {
      data.push({
        type: this.functionTypes[i],
        position: {
          row: 0,
          col: i
        },
        inputs: await this.getEncodedInputData(i + 1, this.functionInputs[i])
      })
    }
    return data
  }

  retrieveWorkflows () {
    this.showLoader = true
    const defaultFilters = { sort_field: 'created', sort_order: 'asc', limit: 1000 }
    this.retrieveWorkflowList({ ...defaultFilters }).then(
      (apiResponse: ApiResponse) => {
        if (!apiResponse.isError && apiResponse.content.status === 200) {
          this.workflows = (apiResponse.content.body.data as WorkflowListResource[]).map(
            function (value: WorkflowListResource) {
              return {
                uuid: value.uuid,
                config_name: value.config_name
              }
            }
          )
          this.showLoader = false
        } else {
          // TODO: handle error
        }
      })
  }

  initCreationDataFromBuildSpec (total: number) {
    this.functionTypes = Array.from({ length: total }, i => '')
    this.functionSpecs = Array.from({ length: total }, i => null)
    this.functionInputs = Array.from({ length: total }, i => null)
    this.verifiedIcons = Array.from({ length: total }, i => 'mdi-checkbox-blank-outline')
    this.verifyIsLoading = Array.from({ length: total }, i => false)
  }

  handleSelectedConfigName (configName: string) {
    this.selectedConfigName = configName
    this.resetCreationState()
    if (configName !== '# blank') {
      let workflowUUID = ''
      for (let i = 0; i < this.workflows.length; i++) {
        if (this.workflows[i].config_name === configName) {
          workflowUUID = this.workflows[i].uuid
          break
        }
      }
      this.retrieveWorkflowDetail(workflowUUID).then(
        (apiResponse: ApiResponse) => {
          this.workflowDetailData = apiResponse.content.body
          const totalJobs = this.workflowDetailData.build_spec.length
          this.initCreationDataFromBuildSpec(totalJobs)
          this.discardTotalJobsUpdate = true
          this.totalJobs = totalJobs
          for (let k = 0; k < this.totalJobs; k++) {
            const functionUUID = this.workflowDetailData.build_spec[k].function.uuid
            this.functionTypes[k] = functionUUID
            this.verifyFunction(k)
          }
        }
      )
    } else {
      this.workflowDetailData = null
      // TODO: Reset all creation data
    }
  }

  async handleCreateStream () {
    this.loadingCreate = true
    const creationData: StreamSpecCreationData = {
      spec: {
        name: this.streamName,
        jobs: await this.buildJobsCreationData()
      },
      metadata: {
        workspace: this.sessionWorkspace.name,
        workflow: this.selectedConfigName !== '# blank' ? this.selectedConfigName : null
      }
    }
    this.createStream(creationData).then((apiResponse) => {
      if (!apiResponse.isError && apiResponse.content.status === 201) {
        const streamUUID = (apiResponse.content.body as Stream).uuid
        const message = `Stream ${streamUUID} has been created.`
        this.createAction({
          kind: 'create_stream',
          level: 'info',
          message: message,
          extra_fields: null
        }).then((_) => {
          this.$toast.info(message, {
            icon: {
              iconClass: 'v-icon mdi mdi-checkbox-marked lightblue--color',
              iconTag: 'i'
            }
          })
          this.$router.push({
            name: 'Stream Detail',
            params: { uuid: streamUUID }
          })
        })
        this.loadingCreate = false
      } else {
        const errorDetail = JSON.stringify(apiResponse.content.body)
        this.createAction({
          kind: 'create_stream',
          level: 'error',
          message: errorDetail,
          extra_fields: { parse_as: 'json' }
        }).then((_) => {
          this.$toast.error(errorDetail, {
            icon: {
              iconClass: 'v-icon mdi mdi-close-box error--color',
              iconTag: 'i'
            }
          })
        })
        this.loadingCreate = false
      }
    })
  }

  mounted () {
    this.retrieveWorkflows()
  }
}
