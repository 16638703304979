

















































































































import Vue from 'vue'
import { mapActions } from 'vuex'
import Component from 'vue-class-component'
import AppCard from '@/components/AppCard.vue'
import { ApiResponse } from '@/js/api'
import { WorkflowListResource } from '@/js/schemas'
import { formatDate } from '@/js/utils'

@Component({
  methods: {
    ...mapActions('workflows', ['retrieveWorkflowList'])
  },
  components: {
    AppCard
  }
})
export default class Workflows extends Vue {
  totalQuerySet: number | null = null
  workflows: WorkflowListResource[] = []
  isLoading: boolean = true
  configNameFilter: string = ''
  usernameFilter: string = ''

  retrieveWorkflowList!: (filterParams: Record<string, any>) => Promise<ApiResponse>

  fmtDate (value: string | Date): string {
    return formatDate(value)
  }

  showWorkflowDetaiView (uuid: string) {
    this.$router.push({ name: 'Workflow Detail', params: { uuid: uuid } })
  }

  getWorkflows () {
    this.isLoading = true
    const defaultFilters = { sort_field: 'created', sort_order: 'asc', limit: 1000 }
    let listFilters = {}
    if (this.configNameFilter !== '') {
      listFilters = { ...listFilters, config_name__contains: this.configNameFilter }
    }
    if (this.usernameFilter !== '') {
      listFilters = { ...listFilters, account__username: this.usernameFilter }
    }
    this.retrieveWorkflowList({ ...defaultFilters, ...listFilters }).then(
      (apiResponse: ApiResponse) => {
        if (!apiResponse.isError && apiResponse.content.status === 200) {
          this.workflows = apiResponse.content.body.data
          this.totalQuerySet = apiResponse.content.body.total_queryset
          this.isLoading = false
        } else {
          // TODO: handle error
        }
      })
  }

  mounted () {
    // TODO: Put all (options, search filters) into the state
    this.getWorkflows()
  }
}
