import Vue from 'vue'
import Vuex from 'vuex'
import root from './root'
import dashboard from './modules/dashboard'
import functions from './modules/functions'
import streams from './modules/streams'
import jobs from './modules/jobs'
import workflows from './modules/workflows'
import configuration from './modules/configuration'

Vue.use(Vuex)

export default new Vuex.Store({
  state: root.state,
  getters: root.getters,
  mutations: root.mutations,
  actions: root.actions,
  modules: {
    dashboard: dashboard,
    functions: functions,
    streams: streams,
    jobs: jobs,
    workflows: workflows,
    configuration: configuration
  }
})
