import { ActionContext } from 'vuex/types'
import {
  ApiResponse,
  handleApiResponse,
  doEntitiesApiCall
} from '@/js/api'

import { FunctionListResource, FunctionCreationData } from '@/js/schemas'
import { RootStateData } from '@/store/root'

interface FunctionsStateData {
  functions: FunctionListResource[],
  yamlCodeString: string
}

// initial state
const state: FunctionsStateData = {
  functions: [],
  yamlCodeString: `# Paste here and edit your \`function.yaml\` configuration file.`
}

type FunctionsContext = ActionContext<FunctionsStateData, RootStateData>

// getters
const getters = {}

// actions
const actions = {
  // Function List
  async retrieveFunctionList (
    context: FunctionsContext,
    filterParams: Record<string, any>
  ): Promise<ApiResponse> {
    return handleApiResponse(
      await doEntitiesApiCall(
        {
          url: 'functions/',
          method: 'get',
          params: { ...filterParams } // TODO: Remove this
        }
      )
    )
  },
  // Fucntion Create
  async createFunction (
    context: FunctionsContext,
    functionSpecCreationData: FunctionCreationData
  ): Promise<ApiResponse> {
    return handleApiResponse(
      await doEntitiesApiCall(
        {
          url: 'functions/',
          method: 'post',
          data: functionSpecCreationData
        }
      )
    )
  },
  // Function Retrieve
  async retrieveFunctionDetail (
    context: FunctionsContext,
    uuid: string
  ): Promise<ApiResponse> {
    return handleApiResponse(
      await doEntitiesApiCall(
        {
          url: `functions/${uuid}/`,
          method: 'get'
        }
      )
    )
  }
}

// mutations
const mutations = {
  setFunctions (state: FunctionsStateData, functions: FunctionListResource[]) {
    state.functions = functions
  },
  setYamlCodeString (state: FunctionsStateData, yamlCodeString: string) {
    state.yamlCodeString = yamlCodeString
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
