
























































































































































































































































import { ApiResponse } from '@/js/api'
import Vue from 'vue'
import { mapActions } from 'vuex'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import {
  CreateCheckoutSessionData
} from '@/js/schemas'
import {
  titleCase
} from '@/js/utils'

@Component({
  methods: {
    ...mapActions(
      'configuration',
      [
        'listScheduledPlans',
        'getMyPlan',
        'getTierQuotas',
        'createCheckoutSession',
        'cancelSubscription'
      ]
    )
  }
})
export default class SubscriptionPlan extends Vue {
  @Prop()
  showCheckoutSuccessDialog!: boolean

  @Prop()
  checkoutSuccess!: boolean

  showLoader: boolean = true
  scheduledPlansData: Array<any> | null = null
  myPlan: any | null = null
  tierQuotas: any | null = null

  options: any = {
    plotOptions: {
      pie: {
        customScale: 1.0,
        donut: {
          size: '0%'
        }
      }
    },
    labels: ['Used', 'Available']
  }

  series: Array<number> = [2, 4]

  showCheckoutDialog: boolean = false

  showCancelPlanDialog: boolean = false

  listScheduledPlans!: () => Promise<ApiResponse>
  getMyPlan!: () => Promise<ApiResponse>
  getTierQuotas!: () => Promise<ApiResponse>
  createCheckoutSession!: (payload: CreateCheckoutSessionData) => Promise<ApiResponse>
  cancelSubscription!: (subscription_id: string) => Promise<ApiResponse>

  get productName (): string | null {
    if (this.myPlan !== null) {
      return (this.myPlan.plan.product_name as string)
    }
    return null
  }

  get planPrices (): Record<string, string> | null {
    if (this.scheduledPlansData) {
      const priceIds: Record<string, string> = {}
      for (let i = 0; i < this.scheduledPlansData.length; i++) {
        const planData = this.scheduledPlansData[i]
        priceIds[planData.name] = planData.price.id
      }
      return priceIds
    }
    return null
  }

  getPlanColorClass (index: number): string {
    switch (index) {
      case 1:
        return 'lightblue1--background'
      default:
        return 'lightgrey--background'
    }
  }

  titleCaseTransform (word: string): string {
    return titleCase(word)
  }

  handleCreateCheckoutSession (planName: string) {
    this.createCheckoutSession(
      { price_id: (this.planPrices as any)[planName] }
    ).then((apiResponse: ApiResponse) => {
      window.location = apiResponse.content.body.checkout_url
    })
  }

  handleCancelCurrentPlan () {
    this.cancelSubscription(this.myPlan.subscription_id).then(
      (apiResponse: ApiResponse) => {
        if (apiResponse.content.status === 204) {
          console.log('error subscription cancellation')
          const planName = this.productName
          this.myPlan = null
          this.tierQuotas = null
          this.fetchInitialStateData()
          this.showCancelPlanDialog = false
          this.$toast.info(`Subscription Plan ${planName} has been cancelled`, {
            icon: {
              iconClass: 'v-icon mdi mdi-checkbox-marked lightblue--color',
              iconTag: 'i'
            }
          })
        } else {
          this.$toast.error(
            'An unexpected error occurred on cancelling your plan, please contact to support.',
            {
              icon: {
                iconClass: 'v-icon mdi mdi-alert-circle error--color',
                iconTag: 'i'
              }
            }
          )
        }
      })
  }

  fetchInitialStateData () {
    this.showLoader = true
    this.getMyPlan().then((apiResponse: ApiResponse) => {
      const body = apiResponse.content.body
      if (body.subscription_id === null) {
        this.myPlan = null
      } else {
        this.myPlan = body
      }
    }).then(() => {
      if (!this.myPlan) {
        this.listScheduledPlans().then((apiResponse: ApiResponse) => {
          this.scheduledPlansData = apiResponse.content.body
          this.showCheckoutDialog = this.showCheckoutSuccessDialog
          this.showLoader = false
        })
      } else {
        this.getTierQuotas().then((apiResponse: ApiResponse) => {
          this.tierQuotas = apiResponse.content.body
          this.showCheckoutDialog = this.showCheckoutSuccessDialog
          this.showLoader = false
        })
      }
    })
  }

  mounted () {
    this.fetchInitialStateData()
  }
}
