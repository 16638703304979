





















































































import { SessionAccount } from '@/js/schemas'
import Vue from 'vue'
import Component from 'vue-class-component'
import { mapState, mapMutations } from 'vuex'

@Component({
  computed: {
    ...mapState(['serverVersion', 'sessionAccount'])
  },
  methods: {
    ...mapMutations(['setSidebarCollapsed'])
  }
})
export default class Sidebar extends Vue {
  serverVersion!: string
  sessionAccount!: SessionAccount

  navArgs = {
    permanent: true,
    miniVariant: true
  }

  navMenu = [
    { title: 'Dashboard', icon: 'mdi-view-dashboard', route: '/dashboard' },
    { title: 'Functions', icon: 'mdi-function', route: '/functions' },
    { title: 'Workflows', icon: 'mdi-folder-wrench-outline', route: '/workflows' },
    { title: 'Streams', icon: 'mdi-vector-line', route: '/streams' },
    { title: 'Jobs', icon: 'mdi-cube-outline', route: '/jobs' }
    // { title: 'Configuration', icon: 'mdi-cog-outline', route: '/configuration' }
  ]

  setSidebarCollapsed!: (collapsed: boolean) => undefined

  toggledCollapse () {
    this.navArgs.miniVariant = !this.navArgs.miniVariant
    this.setSidebarCollapsed(this.navArgs.miniVariant)
  }

  mounted () {
    if (this.sessionAccount.is_business) {
      this.navMenu.push(
        {
          title: 'Configuration',
          icon: 'mdi-cog-outline',
          route: '/configuration'
        }
      )
    }
  }
}
