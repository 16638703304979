import { ActionContext } from 'vuex/types'
import {
  ApiResponse,
  handleApiResponse,
  doAuthApiCall,
  doEntitiesApiCall,
  doApiCall
} from '@/js/api'

// import { WorkflowCreationData } from '@/js/schemas'
import { RootStateData } from '@/store/root'
import { CreateCheckoutSessionData, UserCreationData, WorkspaceAssignmentData, WorkspaceCreationData } from '@/js/schemas'

interface ConfigurationStateData {
  selectedListIndex: number | null
  showAccountCreateForm: boolean,
  showWorkspaceCreateForm: boolean
}

// initial state
const state: ConfigurationStateData = {
  selectedListIndex: null,
  showAccountCreateForm: false,
  showWorkspaceCreateForm: false
}

type ConfigurationContext = ActionContext<ConfigurationStateData, RootStateData>

// getters
const getters = {}

// actions
const actions = {
  // Accounts List
  async retrieveAccountsList (
    context: ConfigurationContext
  ): Promise<ApiResponse> {
    return handleApiResponse(
      await doEntitiesApiCall(
        {
          url: 'accounts/?limit=1000',
          method: 'get'
        }
      )
    )
  },
  // Account Retrieve
  async retrieveAccountDetail (
    context: ConfigurationContext,
    uuid: string
  ): Promise<ApiResponse> {
    return handleApiResponse(
      await doEntitiesApiCall(
        {
          url: `accounts/${uuid}/`,
          method: 'get'
        }
      )
    )
  },
  // Workspaces List
  async retrieveWorkspacesList (
    context: ConfigurationContext
  ): Promise<ApiResponse> {
    return handleApiResponse(
      await doEntitiesApiCall(
        {
          url: 'workspaces/?limit=1000',
          method: 'get'
        }
      )
    )
  },
  // Workspace Retrieve
  async retrieveWorkspaceDetail (
    context: ConfigurationContext,
    uuid: string
  ): Promise<ApiResponse> {
    return handleApiResponse(
      await doEntitiesApiCall(
        {
          url: `workspaces/${uuid}/`,
          method: 'get'
        }
      )
    )
  },
  // User Create
  async createUser (
    context: ConfigurationContext,
    payload: UserCreationData | any
  ): Promise<ApiResponse> {
    return handleApiResponse(
      await doEntitiesApiCall(
        {
          url: 'users/',
          method: 'post',
          data: payload
        }
      )
    )
  },
  // Workspace Create
  async createWorkspace (
    context: ConfigurationContext,
    payload: WorkspaceCreationData | any
  ): Promise<ApiResponse> {
    return handleApiResponse(
      await doEntitiesApiCall(
        {
          url: 'workspaces/',
          method: 'post',
          data: payload
        }
      )
    )
  },
  // Account Patch
  async partialUpdateAccount (
    context: ConfigurationContext,
    payload: any
  ): Promise<ApiResponse> {
    return handleApiResponse(
      await doEntitiesApiCall(
        {
          url: `accounts/${payload.uuid}/`,
          method: 'patch',
          data: payload
        }
      )
    )
  },
  // Workspace Assignment => assign
  async assignWorkspace (
    context: ConfigurationContext,
    payload: WorkspaceAssignmentData | any
  ): Promise<ApiResponse> {
    return handleApiResponse(
      await doAuthApiCall(
        {
          url: 'workspace-assignment/?action=assign',
          method: 'post',
          data: payload
        }
      )
    )
  },
  // Workspace Assignment => unassign
  async unassignWorkspace (
    context: ConfigurationContext,
    payload: WorkspaceAssignmentData | any
  ): Promise<ApiResponse> {
    return handleApiResponse(
      await doAuthApiCall(
        {
          url: 'workspace-assignment/?action=unassign',
          method: 'post',
          data: payload
        }
      )
    )
  },
  // List Scheduled Plans
  async listScheduledPlans (
    context: ConfigurationContext
  ): Promise<ApiResponse> {
    return handleApiResponse(
      await doApiCall(
        {
          url: 'pricing/scheduled-plans/',
          method: 'get'
        }
      )
    )
  },
  // Get My Plan
  async getMyPlan (
    context: ConfigurationContext
  ): Promise<ApiResponse> {
    return handleApiResponse(
      await doApiCall(
        {
          url: 'pricing/my-plan/',
          method: 'get'
        }
      )
    )
  },
  // Get Tier Quotas
  async getTierQuotas (
    context: ConfigurationContext
  ): Promise<ApiResponse> {
    return handleApiResponse(
      await doApiCall(
        {
          url: 'pricing/tier-quotas/',
          method: 'get'
        }
      )
    )
  },
  // Create a Checkout Session
  async createCheckoutSession (
    context: ConfigurationContext,
    payload: CreateCheckoutSessionData | any
  ): Promise<ApiResponse> {
    return handleApiResponse(
      await doApiCall(
        {
          url: 'pricing/create-checkout-session/',
          method: 'post',
          data: payload
        }
      )
    )
  },
  // Cancel subscription
  async cancelSubscription (
    context: ConfigurationContext,
    subscription_id: string
  ): Promise<ApiResponse> {
    return handleApiResponse(
      await doApiCall(
        {
          url: `pricing/cancel-subscription/${subscription_id}/`,
          method: 'delete'
        }
      )
    )
  }
}

// mutations
const mutations = {
  setSelectedListIndex (state: ConfigurationStateData, selectedListIndex: number | null) {
    state.selectedListIndex = selectedListIndex
  },
  setShowAccountCreateForm (state: ConfigurationStateData, showAccountCreateForm: boolean) {
    state.showAccountCreateForm = showAccountCreateForm
  },
  setShowWorkspaceCreateForm (state: ConfigurationStateData, showWorkspaceCreateForm: boolean) {
    state.showWorkspaceCreateForm = showWorkspaceCreateForm
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
