























































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { mapActions, mapMutations, mapState } from 'vuex'
import { EnvVarCreationData, EnvVar } from '@/js/schemas'
import { ApiResponse } from '@/js/api'
import { Watch } from 'vue-property-decorator'

interface EnvVarOptions {
  isEditable: boolean
}

@Component({
  computed: {
    ...mapState([
      'envDialogIsOpen'
    ])
  },
  methods: {
    ...mapActions([
      'retrieveEnvVarList',
      'updateEnvVar',
      'deleteEnvVar',
      'createEnvVar'
    ]),
    ...mapMutations([
      'setWorkspaceEnvVars',
      'setEnvDialogIsOpen'
    ])
  }
})
export default class EnvVarDialog extends Vue {
  $toast: any
  envDialogIsOpen!: boolean

  environmentVariables: EnvVar[] = []
  showLoader = true
  envVarsOptions: Record<number, EnvVarOptions> = {}

  retrieveEnvVarList!: () => Promise<ApiResponse>
  updateEnvVar!: (payload: EnvVar) => Promise<ApiResponse>
  deleteEnvVar!: (uuid: string) => Promise<ApiResponse>
  createEnvVar!: (payload: EnvVarCreationData) => Promise<ApiResponse>
  setEnvDialogIsOpen!: (envDialogIsOpen: boolean) => undefined
  setWorkspaceEnvVars!: (workspaceEnvVars: EnvVar[]) => undefined

  @Watch('envDialogIsOpen')
  watchEnvDialogIsOpen (newValue: boolean) {
    this.showLoader = true

    this.retrieveEnvVarList().then((apiResponse) => {
      const envVarsData = apiResponse.content.body.data as EnvVar[]
      this.setWorkspaceEnvVars(envVarsData)
      this.environmentVariables = envVarsData.map(ev => Object.assign({}, ev))

      for (let i = 0; i < envVarsData.length; i++) {
        this.envVarsOptions[i] = { isEditable: false }
      }
      this.showLoader = false
    })
  }

  makeEditable (index: number) {
    this.envVarsOptions = { ...this.envVarsOptions, [index]: { isEditable: true } }
  }

  discardEnvVarChanges (index: number) {
    const envVars = this.environmentVariables.map((ev) => Object.assign({}, ev))
    for (let i = 0; i < envVars.length; i++) {
      if (i === index) {
        envVars[i] = { ...this.$store.state.workspaceEnvVars[i] }
        this.envVarsOptions[i] = { isEditable: false }
      }
    }
    this.environmentVariables = envVars
  }

  handleDeleteEnvVar (index: number) {
    const envVars = this.environmentVariables.map((ev) => Object.assign({}, ev))
    this.deleteEnvVar(envVars[index].uuid).then(
      (apiResponse) => {
        // 204
        envVars.splice(index, 1)
        this.environmentVariables = envVars
        delete this.envVarsOptions[index]
        this.setWorkspaceEnvVars(this.environmentVariables)
      })
  }

  handleUpdateEnvVar (index: number) {
    const stateEnvVars = this.$store.state.workspaceEnvVars.map(
      (ev: EnvVar) => Object.assign({}, ev)
    )
    for (let i = 0; i < stateEnvVars.length; i++) {
      if (i === index) {
        this.updateEnvVar(this.environmentVariables[i]).then(
          (apiResponse) => {
            // status 200
            stateEnvVars[i] = (apiResponse.content.body as EnvVar)
            this.setWorkspaceEnvVars(stateEnvVars)
            this.envVarsOptions[i] = { isEditable: false }
          })
      }
    }
  }

  handleCreateEnvVar (index: number) {
    const ev = this.environmentVariables[index]
    this.createEnvVar({
      name: ev.name,
      type: ev.type,
      value: ev.value,
      is_hidden: ev.is_hidden
    }).then((apiResponse) => {
      // status 201
      const envVars = this.environmentVariables.map((ev) => Object.assign({}, ev))
      envVars[index] = (apiResponse.content.body as EnvVar)
      this.environmentVariables = envVars
      this.setWorkspaceEnvVars(this.environmentVariables)
      this.envVarsOptions[index] = { isEditable: false }
    })
  }

  validateEnvVar (envVar: EnvVar): boolean {
    const groups: string[] | null = /^[A-Z]([A-Z_0-9])*/g.exec(envVar.name)
    if (groups !== null && groups[0] === envVar.name) {
      return true
    } else {
      // TODO: show toast notification error
      this.$toast.error('Variable name is not valid. It must follow the following naming pattern ^[A-Z]([A-Z_0-9])*', {
        icon: {
          iconClass: 'v-icon mdi mdi-alert-circle error--color',
          iconTag: 'i'
        }
      })
      return false
    }
  }

  saveEnvVarChanges (index: number) {
    if (this.validateEnvVar(this.environmentVariables[index])) {
      if (index > (this.$store.state.workspaceEnvVars.length - 1)) {
        this.handleCreateEnvVar(index)
      } else {
        this.handleUpdateEnvVar(index)
      }
    }
  }

  addNewEmptyVariable () {
    const newLength = this.environmentVariables.push({
      uuid: '',
      created: '',
      updated: '',
      type: 'text',
      name: '',
      value: '',
      is_hidden: false,
      workspace: this.$store.state.sessionWorkspace.uuid
    })
    this.envVarsOptions = { ...this.envVarsOptions, [newLength - 1]: { isEditable: true } }
  }
}
