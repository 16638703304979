import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Dashboard from '@/views/Dashboard.vue'
import Functions from '@/views/Functions.vue'
import FunctionDetail from '@/views/FunctionDetail.vue'
import FunctionCreate from '@/views/FunctionCreate.vue'
import Workflows from '@/views/Workflows.vue'
import WorkflowDetail from '@/views/WorkflowDetail.vue'
import Streams from '@/views/Streams.vue'
import StreamCreate from '@/views/StreamCreate.vue'
import StreamDetail from '@/views/StreamDetail.vue'
import Jobs from '@/views/Jobs.vue'
import JobDetail from '@/views/JobDetail.vue'
import Configuration from '@/views/Configuration.vue'
import SignIn from '@/views/SignIn.vue'
import SignUp from '@/views/SignUp.vue'
import ResetPassword from '@/views/ResetPassword.vue'

import root from '@/store/root'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: {
      requiresAuth: true
    },
    component: Dashboard
  },
  {
    path: '/functions',
    name: 'Functions',
    meta: {
      requiresAuth: true
    },
    component: Functions
  },
  {
    path: '/functions/create',
    name: 'Function Create',
    meta: {
      requiresAuth: true
    },
    component: FunctionCreate
  },
  {
    path: '/functions/:uuid',
    name: 'Function Detail',
    meta: {
      requiresAuth: true
    },
    component: FunctionDetail
  },
  {
    path: '/workflows',
    name: 'Workflows',
    meta: {
      requiresAuth: true
    },
    component: Workflows
  },
  {
    path: '/workflows/:uuid',
    name: 'Workflow Detail',
    meta: {
      requiresAuth: true
    },
    component: WorkflowDetail
  },
  {
    path: '/streams',
    name: 'Streams',
    meta: {
      requiresAuth: true
    },
    component: Streams
  },
  {
    path: '/streams/create',
    name: 'Stream Create',
    meta: {
      requiresAuth: true
    },
    component: StreamCreate
  },
  {
    path: '/streams/:uuid',
    name: 'Stream Detail',
    meta: {
      requiresAuth: true
    },
    component: StreamDetail
  },
  {
    path: '/jobs',
    name: 'Jobs',
    meta: {
      requiresAuth: true
    },
    component: Jobs
  },
  {
    path: '/jobs/:uuid',
    name: 'Job Detail',
    meta: {
      requiresAuth: true
    },
    component: JobDetail
  },
  {
    path: '/configuration',
    name: 'Configuration',
    meta: {
      requiresAuth: true
    },
    component: Configuration
  },
  {
    path: '/sign-in',
    name: 'SignIn',
    meta: {
      requiresAuth: false
    },
    component: SignIn
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    meta: {
      requiresAuth: false
    },
    component: SignUp
  },
  {
    path: '/reset-password',
    name: 'Reset Password',
    meta: {
      requiresAuth: false
    },
    component: ResetPassword
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// Guard that cheks if session is active and redirects to proper route.
router.beforeEach(async (to, from, next) => {
  if (!root.state.isSessionActive) {
    // check active session
    await root.actions.checkActiveSession()
      .then((apiResponse) => {
        // pass
        (root.state.isSessionActive as boolean | any) = apiResponse.content.body.success
      })
  }
  if (root.state.isSessionActive) {
    if (to.meta !== undefined && to.meta.requiresAuth) {
      next()
    } else {
      next({ name: 'Dashboard' })
    }
  } else {
    if (
      to.meta !== undefined &&
      Object.keys(to.meta).includes('requiresAuth') &&
      !to.meta.requiresAuth
    ) {
      next()
    } else {
      next({ name: 'SignIn' })
    }
  }
})

export default router
