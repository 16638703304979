





























































































































































































































































































































































































import Vue from 'vue'
import { mapActions, mapMutations } from 'vuex'
import Component from 'vue-class-component'
import AppCard from '@/components/AppCard.vue'
import JobCube from '@/components/JobCube.vue'
import WorkflowCreationDialog from '@/components/WorkflowCreationDialog.vue'
import { Stream, Job, ActionCreationData, Variable } from '@/js/schemas'
import { ApiResponse, buildSocketURI } from '@/js/api'
import {
  formatDate, groupJobInputs, groupJobOutputs,
  getDecodedVariableData, downloadFileObject
}
  from '@/js/utils'

@Component({
  methods: {
    ...mapActions(['createAction']),
    ...mapActions('streams', ['retrieveStreamDetail', 'pushStream', 'cancelStream']),
    ...mapMutations('streams', [
      'setWorkflowCreationDialogIsOpen'
    ])
  },
  components: {
    AppCard,
    JobCube,
    WorkflowCreationDialog
  }
})
export default class StreamDetail extends Vue {
  showLoader: boolean = true
  streamUUID: string = ''
  streamDetailData: Stream | null = null
  jobIndex: number = 0
  tryCancel: boolean = false

  createAction!: (payload: ActionCreationData) => Promise<ApiResponse>
  retrieveStreamDetail!: (uuid: string) => Promise<ApiResponse>
  pushStream!: (uuid: string) => Promise<ApiResponse>
  cancelStream!: (uuid: string) => Promise<ApiResponse>
  setWorkflowCreationDialogIsOpen!: (workflowCreationDialogIsOpen: boolean) => undefined

  get jobData (): Job | null {
    return (this.streamDetailData as Stream).jobs[this.jobIndex]
  }

  get streamStatus (): string {
    return this.streamDetailData?.status !== undefined ? this.streamDetailData?.status : ''
  }

  get showRunLoader () {
    if (this.tryCancel) {
      return true
    }
    if (this.streamDetailData?.status === 'pushed') {
      return true
    }
    return false
  }

  get streamTemplate () {
    let jobs: Job[] = []
    if (this.streamDetailData) {
      jobs = (this.streamDetailData as Stream).jobs
    }
    return jobs.map(
      function (value: Job) {
        return {
          function: {
            key: value.function.key,
            version: value.function.version
          },
          position: value.position
        }
      }
    )
  }

  fmtDate (value: string | Date): string {
    return formatDate(value)
  }

  getCubeImageUrl (status: string): string {
    const images = require.context('@/assets/', false, /\.png$/)
    return images(`./cube-${status}.png`)
  }

  async copyUUIDToClipboard () {
    await navigator.clipboard.writeText(this.streamUUID)
  }

  setSelectedJobIndex (index: number) {
    this.jobIndex = index
  }

  setActiveJobIndex () {
    for (let i = 0; i < (this.streamDetailData as Stream).jobs.length; i++) {
      if ((this.streamDetailData as Stream).jobs[i].status !== 'completed') {
        this.setSelectedJobIndex(i)
        return
      }
    }
    this.setSelectedJobIndex(0)
  }

  getGroupedJobInputs () {
    return groupJobInputs(this.jobData)
  }

  getGroupedJobOutputs () {
    return groupJobOutputs(this.jobData)
  }

  orderStreamJobs (data: Stream): Stream {
    data.jobs.sort((a: Job, b: Job) => {
      if (a.position.col > b.position.col) {
        return 1
      } else {
        return -1
      }
    })
    return data
  }

  getDecodedVariableData (variable: Variable): string {
    if (variable.bytes) {
      return getDecodedVariableData(variable)
    } else {
      if (variable.binding !== null) {
        return `${variable.binding.id_name}`
      }
      return 'None'
    }
  }

  downloadFileObject (variable: Variable) {
    downloadFileObject(variable)
  }

  showWorkflowDetailView (uuid: string) {
    this.$router.push({ name: 'Workflow Detail', params: { uuid: uuid } })
  }

  handlePushStream () {
    this.pushStream(this.streamUUID).then((apiResponse: ApiResponse) => {
      let error: string | null = null
      let errorExtraFields: Record<string, string> | null = null
      if (!apiResponse.isError && apiResponse.content.status === 200) {
        const respBody = apiResponse.content.body
        if (!respBody.ok) {
          error = respBody.error
        }
      } else {
        error = JSON.stringify(apiResponse.content.body)
        errorExtraFields = { parse_as: 'json' }
      }

      if (error === null) {
        const successMsg = `Pushed Stream '${this.streamUUID}' with success.`
        this.createAction({
          kind: 'push_stream',
          level: 'info',
          message: successMsg,
          extra_fields: null
        }).then((_) => {
          this.$toast.info(
            successMsg,
            {
              icon: {
                iconClass: 'v-icon mdi mdi-checkbox-marked lightblue--color',
                iconTag: 'i'
              }
            })
        })
      } else {
        const errorMsg: string = error
        this.createAction({
          kind: 'push_stream',
          level: 'error',
          message: errorMsg,
          extra_fields: errorExtraFields
        }).then((_) => {
          this.$toast.error(errorMsg, {
            icon: {
              iconClass: 'v-icon mdi mdi-close-box error--color',
              iconTag: 'i'
            }
          })
        })
      }
    })
  }

  handleCancelStream () {
    this.tryCancel = true
    this.cancelStream(this.streamUUID).then((apiResponse) => {
      if (!apiResponse.isError && apiResponse.content.status === 200) {
        this.$toast.info(
          `Cancelled stream '${this.streamUUID}'; all jobs are also cancelled and cannot run any more.`,
          {
            icon: {
              iconClass: 'v-icon mdi mdi-checkbox-marked lightblue--color',
              iconTag: 'i'
            }
          })
      } else {
        this.$toast.error('An unnexpected error occurred while trying to cancel the Stream.', {
          icon: {
            iconClass: 'v-icon mdi mdi-close-box error--color',
            iconTag: 'i'
          }
        })
      }
      this.tryCancel = false
    })
  }

  streamSocketConnect (uuid: string) {
    const socketURI = buildSocketURI(`realtime/streams/${uuid}/`)
    const streamSocket = new WebSocket(socketURI)

    const vueInstance = this

    streamSocket.onmessage = function (ev) {
      const messageData = JSON.parse(ev.data)
      vueInstance.streamDetailData = vueInstance.orderStreamJobs(
        JSON.parse(messageData.stream_data)
      )
      if (vueInstance.jobData?.status === 'completed') {
        vueInstance.setActiveJobIndex()
      }
    }

    streamSocket.onclose = function (ev) {
      console.error('Stream socket closed unexpectedly')
    }
  }

  mounted () {
    // TODO: assert UUID exists, either redirect to a 404 error page.
    this.streamUUID = this.$route.params.uuid
    this.retrieveStreamDetail(this.streamUUID).then((apiResponse: ApiResponse) => {
      this.streamDetailData = this.orderStreamJobs(apiResponse.content.body)
      this.setActiveJobIndex()
      this.streamSocketConnect(this.streamUUID)
      this.showLoader = false
    })
  }
}
