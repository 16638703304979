











































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { mapActions } from 'vuex'
import {
  ResetPasswordData
} from '@/js/schemas'
import { ApiResponse } from '@/js/api'

@Component({
  methods: {
    ...mapActions(['resetPassword'])
  }
})
export default class ResetPassword extends Vue {
  $router: any
  $route: any
  loading = false
  valid = true
  showPassword = false
  authId = ''
  token = ''
  resetPasswordSuccess = false
  resetPasswordError = ''

  password = ''
  passwordRules = [
    (v: string) => !!v || 'Password is required',
    (v: string) =>
      (v && v.length >= 6) || 'Password must have a minimum of 6 characters'
  ]

  passwordConfirm = ''

  get passwordConfirmRules () {
    return [
      (v: string) => !!v || 'Password confirmation is required',
      (v: string) =>
        (v && v === this.password) || 'Passwords don\'t match.'
    ]
  }

  get organizationSlug () {
    return this.authId.split(':')[0]
  }

  get username () {
    return this.authId.split(':')[1]
  }

  get signInURI () {
    return (
      '/sign-in/?type=authid&organization_slug=' +
      `${this.organizationSlug}` +
      `&username=${this.username}`
    )
  }

  // Type mapped methods
  resetPassword!: (payload: ResetPasswordData) => Promise<ApiResponse>

  // Methods
  initStateWithQueryParams () {
    this.authId = (this.$route.query.auth_id as string)
    this.token = (this.$route.query.token as string)
  }

  handleResetPasswordForm () {
    this.resetPassword({
      auth_id: this.authId,
      token: this.token,
      password: this.password
    }).then((apiResponse) => {
      if (apiResponse.isError && apiResponse.content.status === 400) {
        this.resetPasswordSuccess = false
        // TODO: show error notification
        this.resetPasswordError = (apiResponse.content.body as string[])[0]
        this.$toast.error(
          this.resetPasswordError,
          {
            icon: {
              iconClass: 'v-icon mdi mdi-close-box error--color',
              iconTag: 'i'
            }
          }
        )
      }
      if (!apiResponse.isError && apiResponse.content.status === 200) {
        this.resetPasswordSuccess = true
      }
    })
  }

  mounted () {
    this.initStateWithQueryParams()
  }
}
