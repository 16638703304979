


































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import Component from 'vue-class-component'
import AppCard from '@/components/AppCard.vue'
import AccountCreateForm from '@/components/AccountCreateForm.vue'
import WorkspaceCreateForm from '@/components/WorkspaceCreateForm.vue'
import SubscriptionPlan from '@/components/SubscriptionPlan.vue'
import { Watch } from 'vue-property-decorator'
import { ApiResponse } from '@/js/api'
import {
  Account,
  AccountListResource,
  Workspace,
  WorkspaceListResource,
  WorkspaceAssignmentData,
  SessionAccount
} from '@/js/schemas'
import { DataTableOptions, formatDate } from '@/js/utils'

@Component({
  computed: {
    ...mapState(['sessionAccount', 'appConfigData']),
    ...mapState(
      'configuration',
      [
        'selectedListIndex',
        'showAccountCreateForm',
        'showWorkspaceCreateForm'
      ]
    ),
    ...mapState(['sessionAccount'])
  },
  methods: {
    ...mapActions(
      'configuration',
      [
        'retrieveAccountsList',
        'retrieveAccountDetail',
        'retrieveWorkspacesList',
        'retrieveWorkspaceDetail',
        'partialUpdateAccount',
        'assignWorkspace',
        'unassignWorkspace'
      ]
    ),
    ...mapActions(['generateResetPasswordToken']),
    ...mapMutations(
      'configuration',
      [
        'setSelectedListIndex',
        'setShowAccountCreateForm',
        'setShowWorkspaceCreateForm'
      ]
    )
  },
  components: {
    AppCard,
    AccountCreateForm,
    WorkspaceCreateForm,
    SubscriptionPlan
  }
})
export default class Configuration extends Vue {
  appConfigData!: any
  sessionAccount!: SessionAccount
  selectedListIndex!: number
  showAccountCreateForm!: boolean
  showWorkspaceCreateForm!: boolean

  userAccounts: Array<AccountListResource> = []
  businessWorkspaces: Array<WorkspaceListResource> = []
  accountSearchValue: string | null = null
  workspaceSearchValue: string | null = null
  accountsTableHeaders = [
    {
      text: 'UUID',
      align: 'start',
      value: 'uuid',
      class: 'app-table--header',
      cellClass: 'app-table--cell'
    },
    {
      text: 'Username',
      value: 'username',
      class: 'app-table--header',
      cellClass: 'app-table--cell'
    },
    {
      text: 'Created At',
      value: 'created',
      class: 'app-table--header',
      cellClass: 'app-table--cell'
    },
    {
      text: 'Is Active',
      value: 'is_active',
      class: 'app-table--header',
      cellClass: 'app-table--cell'
    },
    {
      text: 'Groups',
      value: 'groups',
      class: 'app-table--header',
      cellClass: 'app-table--cell'
    },
    {
      text: 'Actions',
      value: 'actions',
      class: 'app-table--header',
      cellClass: 'app-table--cell'
    }
  ]

  workspaceTableHeaders = [
    {
      text: 'UUID',
      align: 'start',
      value: 'uuid',
      class: 'app-table--header',
      cellClass: 'app-table--cell'
    },
    {
      text: 'Name',
      value: 'name',
      class: 'app-table--header',
      cellClass: 'app-table--cell'
    },
    {
      text: 'Created At',
      value: 'created',
      class: 'app-table--header',
      cellClass: 'app-table--cell'
    },
    {
      text: 'User Scoped',
      value: 'user_scoped',
      class: 'app-table--header',
      cellClass: 'app-table--cell'
    },
    {
      text: 'Actions',
      value: 'actions',
      class: 'app-table--header',
      cellClass: 'app-table--cell'
    }
  ]

  tableOptions: DataTableOptions = {
    groupBy: [],
    groupDesc: [],
    sortBy: ['updated'],
    sortDesc: [true],
    page: 1,
    itemsPerPage: 10,
    multiSort: false,
    mustSort: false
  }

  tableFooterProps = {
    'items-per-page-options': [5, 10, 20, 40],
    'items-per-page-text': 'Paginated by:'
  }

  selectedAccountUUID: string | null = null
  accountDetail: Account | null = null
  accountsTableIsLoading: boolean = true

  selectedWorkspaceUUID: string | null = null
  workspaceDetail: Workspace | null = null
  workspaceTableIsLoading: boolean = true

  activeAccountConfirmDialog: boolean = false
  makeInactive: boolean | null = null

  resetPasswordDialog: boolean = false
  resetPasswordLink: string | null = null

  workspaceAssignmentDialog: boolean = false
  workspaceUnassignmentDialog: boolean = false
  assignedAccountUUID: string | null = null

  unassignableAccount: Record<'uuid' | 'username', string> | null = null

  checkoutStatus: string | null = null
  checkoutSuccess: boolean | null = null

  retrieveAccountsList!: () => Promise<ApiResponse>
  retrieveWorkspacesList!: () => Promise<ApiResponse>
  retrieveAccountDetail!: (uuid: string) => Promise<ApiResponse>
  retrieveWorkspaceDetail!: (uuid: string) => Promise<ApiResponse>
  partialUpdateAccount!: (payload: any) => Promise<ApiResponse>
  assignWorkspace!: (payload: WorkspaceAssignmentData) => Promise<ApiResponse>
  unassignWorkspace!: (payload: WorkspaceAssignmentData) => Promise<ApiResponse>
  generateResetPasswordToken!: (payload: any) => Promise<ApiResponse>
  setSelectedListIndex!: (selectedListIndex: number) => undefined
  setShowAccountCreateForm!: (showAccountCreateForm: boolean) => undefined
  setShowWorkspaceCreateForm!: (showWorkspaceCreateForm: boolean) => undefined

  get groupIndex () {
    return this.selectedListIndex
  }

  set groupIndex (groupIndex: number) {
    this.setSelectedListIndex(groupIndex)
  }

  get accountFormattedData (): any | null {
    if (this.accountDetail !== null) {
      return {
        'auth id': this.accountDetail.auth_id,
        username: this.accountDetail.username,
        email: this.accountDetail.email,
        created: this.fmtDate(this.accountDetail.created),
        updated: this.fmtDate(this.accountDetail.updated),
        'is business': this.accountDetail.is_business,
        'is active': this.accountDetail.is_active,
        'date joined': this.fmtDate(this.accountDetail.date_joined),
        'last login': this.accountDetail.last_login !== null ? this.fmtDate(this.accountDetail.last_login) : null,
        groups: this.accountDetail.groups,
        workspaces: this.accountDetail.workspaces.map((value) => value.name)
      }
    }
    return null
  }

  get workspaceFormattedData (): any | null {
    if (this.workspaceDetail !== null) {
      return {
        name: this.workspaceDetail.name,
        description: this.workspaceDetail.description,
        created: this.fmtDate(this.workspaceDetail.created),
        updated: this.fmtDate(this.workspaceDetail.updated),
        'user scoped': this.workspaceDetail.user_scoped
      }
    } else {
      return null
    }
  }

  get workspaceAccounts (): Array<Record<'uuid' | 'username', string>> | null {
    if (this.workspaceDetail !== null) {
      return this.workspaceDetail.accounts
    } else {
      return null
    }
  }

  get showBilling () {
    return this.appConfigData.features.pricing && this.sessionAccount.is_business
  }

  @Watch('groupIndex')
  handleGroupIndex (groupIndex: number) {
    // Reset showed views
    this.setShowAccountCreateForm(false)
    this.setShowWorkspaceCreateForm(false)

    if (groupIndex === 0) {
      this.selectedAccountUUID = null
      this.userAccounts = []
      this.accountsTableIsLoading = true
      this.retrieveAccountsList().then((apiResponse: ApiResponse) => {
        console.log('Retrieve user accounts ...')
        this.userAccounts = apiResponse.content.body.data
        this.accountsTableIsLoading = false
      })
    } else if (this.groupIndex === 1) {
      this.selectedWorkspaceUUID = null
      this.businessWorkspaces = []
      this.workspaceTableIsLoading = true
      this.retrieveWorkspacesList().then((apiResponse: ApiResponse) => {
        this.businessWorkspaces = apiResponse.content.body.data
        this.workspaceTableIsLoading = false
      })
    } else if (groupIndex === undefined) {
      this.groupIndex = 0
    }
  }

  @Watch('selectedAccountUUID')
  handleSelectedAccountUUID (uuid: string) {
    this.accountDetail = null
    if (uuid) {
      this.retrieveAccountDetail(uuid).then((apiResponse: ApiResponse) => {
        this.accountDetail = apiResponse.content.body
      })
    }
  }

  @Watch('selectedWorkspaceUUID')
  handleSelectedWorkspaceUUID (uuid: string) {
    this.workspaceDetail = null
    if (uuid) {
      this.retrieveWorkspaceDetail(uuid).then((apiResponse: ApiResponse) => {
        // todo: retrieve workspace assignable accounts
        this.workspaceDetail = apiResponse.content.body
      })
    }
  }

  fmtDate (value: string | Date): string {
    return formatDate(value)
  }

  async copyUUIDToClipboard (uuid: string | null) {
    await navigator.clipboard.writeText(uuid !== null ? uuid : '')
  }

  setupActiveAccountConfirmDialog (accountStatus: boolean) {
    this.makeInactive = !accountStatus
    this.activeAccountConfirmDialog = true
  }

  cancelUpdateAccountIsActive () {
    this.makeInactive = null
    this.activeAccountConfirmDialog = false
  }

  handleUpdateAccountIsActive () {
    this.partialUpdateAccount(
      {
        uuid: (this.accountDetail as Account).uuid,
        is_active: !this.makeInactive
      }
    ).then((apiResponse: ApiResponse) => {
      this.$toast.info(`Updated [${this.makeInactive ? 'inactive' : 'active'}] user account '${this.accountDetail?.uuid}'`, {
        icon: {
          iconClass: 'v-icon mdi mdi-checkbox-marked lightblue--color',
          iconTag: 'i'
        }
      })
      this.accountDetail = apiResponse.content.body
      this.activeAccountConfirmDialog = false
    })
  }

  handleGetResetPasswordLink () {
    this.resetPasswordLink = null
    this.generateResetPasswordToken({ auth_id: this.accountDetail?.auth_id }).then(
      (apiResponse: ApiResponse) => {
        console.log('Location', window.location.origin)
        console.log('Route', this.$router.currentRoute)
        this.resetPasswordLink = (
          `${window.location.origin}/reset-password/` +
          `?auth_id=${this.accountDetail?.auth_id}` +
          `&token=${apiResponse.content.body.token}`
        )
        this.resetPasswordDialog = true
      }
    )
  }

  async copyResetPasswordLink () {
    await navigator.clipboard.writeText(this.resetPasswordLink !== null ? this.resetPasswordLink : '')
  }

  openWorkspaceUnassignmentDialog (
    account: Record<'uuid' | 'username', string>
  ) {
    this.unassignableAccount = account
    this.workspaceUnassignmentDialog = true
  }

  handleWorkspaceAssignment (action: string) {
    if (action === 'assign') {
      const body = {
        account: (this.assignedAccountUUID as string),
        workspace: (this.workspaceDetail?.uuid as string)
      }
      this.assignWorkspace(body).then((apiResponse: ApiResponse) => {
        if (apiResponse.isError) {
          console.log(apiResponse)
          this.$toast.error(`Error on Assignment: ${apiResponse.content.body.detail}`, {
            icon: {
              iconClass: 'v-icon mdi mdi-alert-circle error--color',
              iconTag: 'i'
            }
          })
        } else {
          this.$toast.info(`Assigned account '${body.account}' to workspace '${body.workspace}'`, {
            icon: {
              iconClass: 'v-icon mdi mdi-checkbox-marked lightblue--color',
              iconTag: 'i'
            }
          })
          this.retrieveWorkspaceDetail((this.workspaceDetail as Workspace).uuid).then(
            (apiResponse: ApiResponse) => {
              this.workspaceDetail = apiResponse.content.body
              this.workspaceAssignmentDialog = false
              this.unassignableAccount = null
            }
          )
        }
      })
    } else {
      const body = {
        account: (this.unassignableAccount as any).uuid,
        workspace: (this.workspaceDetail?.uuid as string)
      }
      this.unassignWorkspace(body).then((apiResponse: ApiResponse) => {
        if (apiResponse.isError) {
          console.log(apiResponse)
          this.$toast.error(`Error on Unassignment: ${apiResponse.content.body.detail}`, {
            icon: {
              iconClass: 'v-icon mdi mdi-alert-circle error--color',
              iconTag: 'i'
            }
          })
        } else {
          this.$toast.info(`Unassigned account '${body.account}' from workspace '${body.workspace}'`, {
            icon: {
              iconClass: 'v-icon mdi mdi-checkbox-marked lightblue--color',
              iconTag: 'i'
            }
          })
          this.retrieveWorkspaceDetail((this.workspaceDetail as Workspace).uuid).then(
            (apiResponse: ApiResponse) => {
              this.workspaceDetail = apiResponse.content.body
              this.workspaceUnassignmentDialog = false
              this.assignedAccountUUID = null
            }
          )
        }
      })
    }
  }

  initStateWithQueryParams () {
    const tab = this.$route.query.tab
    switch (tab) {
      case 'billing':
        this.setSelectedListIndex(2)
        break
      case 'workspaces':
        this.setSelectedListIndex(1)
        break
      default:
        this.setSelectedListIndex(0)
    }
    const status = this.$route.query.checkoutStatus
    this.checkoutStatus = (status !== undefined ? (status as string) : null)
    if (this.checkoutStatus) {
      this.checkoutSuccess = this.checkoutStatus === 'success'
    }
  }

  mounted () {
    this.initStateWithQueryParams()
  }
}
