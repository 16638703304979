




import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { mapMutations } from 'vuex'
import { PrismEditor } from 'vue-prism-editor'
import 'vue-prism-editor/dist/prismeditor.min.css'
import { highlight, languages } from 'prismjs'
import 'prismjs/components/prism-yaml'
import 'prismjs/themes/prism-coy.css'

const YamlEditorProps = Vue.extend({
  props: {
    yamlCodeString: String
  }
})

@Component({
  methods: {
    ...mapMutations('functions', [
      'setYamlCodeString'
    ])
  },
  components: {
    PrismEditor
  }
})
export default class YamlEditor extends YamlEditorProps {
  code = this.yamlCodeString

  setYamlCodeString!: (yamlCodeString: string) => undefined

  @Watch('code')
  watchCode (newValue: string) {
    this.setYamlCodeString(newValue)
  }

  highlighter (code: string) {
    return highlight(code, languages.yaml, 'yaml')
  }
}
