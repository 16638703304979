




























import {
  WorkspaceCreationData,
  Workspace
} from '@/js/schemas'
import Vue from 'vue'
import { mapState, mapActions, mapMutations } from 'vuex'
import Component from 'vue-class-component'
import { ApiResponse } from '@/js/api'

@Component({
  computed: {
    ...mapState(['sessionWorkspace'])
  },
  methods: {
    ...mapActions(
      'configuration',
      ['createWorkspace']
    ),
    ...mapMutations(
      'configuration',
      [
        'setSelectedListIndex',
        'setShowWorkspaceCreateForm'
      ]
    )
  }
})
export default class WorkspaceCreateForm extends Vue {
  sessionWorkspace!: Workspace
  valid = true

  name = ''
  nameRules = [
    (v: string) => !!v || 'Name is required',
    (v: string) =>
      (v && v.length >= 4) || 'Must have a minimum of 4 characters'
  ]

  description = ''
  descriptionRules = [
    (v: string) => !!v || 'Description is required',
    (v: string) =>
      (v && v.length >= 10) || 'Must have a minimum of 10 characters'
  ]

  createWorkspace!: (payload: WorkspaceCreationData) => Promise<ApiResponse>
  setSelectedListIndex!: (selectedListIndex: number | null) => undefined
  setShowWorkspaceCreateForm!: (showAccountCreateForm: boolean) => undefined

  handleCreateWorkspace () {
    if (!(this.$refs.createWorkspaceForm as any).validate()) {
      // todo: raise errors
      return
    }
    // this.loading = true
    const data: WorkspaceCreationData = {
      name: this.name,
      business: this.sessionWorkspace.business,
      description: this.description,
      user_scoped: false
    }
    this.createWorkspace(data).then((apiResponse) => {
      if (!apiResponse.isError) {
        const workspaceData: Workspace = apiResponse.content.body
        this.$toast.info(`A new workspace has been created uuid='${workspaceData.uuid}'`, {
          icon: {
            iconClass: 'v-icon mdi mdi-checkbox-marked lightblue--color',
            iconTag: 'i'
          }
        })
        this.setSelectedListIndex(null)
        this.setSelectedListIndex(1)
        this.setShowWorkspaceCreateForm(false)
      } else {
        this.$toast.error(apiResponse.content.body.detail, {
          icon: {
            iconClass: 'v-icon mdi mdi-alert-circle error--color',
            iconTag: 'i'
          }
        })
      }
      // this.loading = false
    })
  }
}
