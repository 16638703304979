import { ActionContext } from 'vuex/types'
import {
  ApiResponse,
  handleApiResponse,
  doEntitiesApiCall
} from '@/js/api'

import { WorkflowCreationData } from '@/js/schemas'
import { RootStateData } from '@/store/root'

interface WorkflowsStateData {
}

// initial state
const state: WorkflowsStateData = {}

type WorkflowsContext = ActionContext<WorkflowsStateData, RootStateData>

// getters
const getters = {}

// actions
const actions = {
  // Workflow List
  async retrieveWorkflowList (
    context: WorkflowsContext,
    filterParams: Record<string, any>
  ): Promise<ApiResponse> {
    return handleApiResponse(
      await doEntitiesApiCall(
        {
          url: 'workflows/',
          method: 'get',
          params: { ...filterParams }
        }
      )
    )
  },
  // Workflow Create
  async createWorkflow (
    context: WorkflowsContext,
    workflowCreationData: WorkflowCreationData
  ): Promise<ApiResponse> {
    return handleApiResponse(
      await doEntitiesApiCall(
        {
          url: 'workflows/',
          method: 'post',
          data: workflowCreationData
        }
      )
    )
  },
  // Workflow Retrieve
  async retrieveWorkflowDetail (
    context: WorkflowsContext,
    uuid: string
  ): Promise<ApiResponse> {
    return handleApiResponse(
      await doEntitiesApiCall(
        {
          url: `workflows/${uuid}/`,
          method: 'get'
        }
      )
    )
  }
}

// mutations
const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
