



































































































































































































































import Vue from 'vue'
import { mapActions } from 'vuex'
import Component from 'vue-class-component'
import AppCard from '@/components/AppCard.vue'
import { ApiResponse } from '@/js/api'

@Component({
  methods: {
    ...mapActions('dashboard', ['retrieveCountList'])
  },
  components: {
    AppCard
  }
})

export default class Dashboard extends Vue {
  stream_counts = {}
  jobs_counts = {}
  stream_count_by_date_created: Record<string, number> = {}
  stream_count_by_date_finished: Record<string, number> = {}
  job_count_by_date_created: Record<string, number> = {}
  jobs_count_by_date_finished: Record<string, number> = {}
  showSeries: boolean = false
  showCount: boolean = false

  barOptions = {
    chart: {
      type: 'area',
      height: 350,
      toolbar: {
        show: true
      },
      zoom: {
        enabled: true
      }
    },
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          position: 'bottom',
          offsetX: -10,
          offsetY: 0
        }
      }
    }],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10
      }
    },
    xaxis: {
      type: 'datetime'
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy'
      }
    },
    legend: {
      position: 'right',
      offsetY: 40
    },
    fill: {
      opacity: 1
    }
  }

  retrieveCountList!: () => Promise<ApiResponse>

  get barSeriesStream () {
    const createdData = Object.keys(this.stream_count_by_date_created).map(
      (index: string, value: number, _: string[]) => {
        return { x: Date.parse(index), y: this.stream_count_by_date_created[index] }
      })
    const series = [{ name: 'Created', data: createdData }]
    if (this.stream_count_by_date_finished.length !== 0) {
      const finishedData = Object.keys(this.stream_count_by_date_finished as any).map(
        (index: string, value: number, _: string[]) => {
          return { x: Date.parse(index), y: (this.stream_count_by_date_finished as any)[index] }
        })

      series.push({ name: 'Finished', data: finishedData })
    }
    return series
  }

  get barSeriesJobs () {
    const createdData = Object.keys(this.job_count_by_date_created).map(
      (index: string, value: number, _: string[]) => {
        return { x: Date.parse(index), y: this.job_count_by_date_created[index] }
      })
    const series = [{ name: 'Created', data: createdData }]

    if (this.jobs_count_by_date_finished.length !== 0) {
      const finishedData = Object.keys(this.jobs_count_by_date_finished as any).map(
        (index: string, value: number, _: string[]) => {
          return { x: Date.parse(index), y: (this.jobs_count_by_date_finished as any)[index] }
        })
      series.push({ name: 'Finished', data: finishedData })
    }
    return series
  }

  getDashboardData () {
    this.retrieveCountList().then((apiResponse: ApiResponse) => {
      if (!apiResponse.isError && apiResponse.content.status === 200) {
        this.stream_counts = apiResponse.content.body.streams_count
        this.jobs_counts = apiResponse.content.body.jobs_count
        this.showCount = true
      } else {
        // TODO: handle error
      }
    })
  }

  getStreamDataSeries () {
    this.retrieveCountList().then((apiResponse: ApiResponse) => {
      if (!apiResponse.isError && apiResponse.content.status === 200) {
        this.stream_count_by_date_created = apiResponse.content.body.streams_count_by_date.created
        this.stream_count_by_date_finished = apiResponse.content.body.streams_count_by_date.finished
        this.job_count_by_date_created = apiResponse.content.body.jobs_count_by_date.created
        this.jobs_count_by_date_finished = apiResponse.content.body.jobs_count_by_date.finished
        this.showSeries = true
      } else {
        // TODO: handle error
      }
    })
  }

  mounted () {
    this.getDashboardData()
    this.getStreamDataSeries()
  }
}
