var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"text-center"},[_c('v-col',{attrs:{"cols":"12"}},[_c('app-card',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Streams Count ")]},proxy:true},{key:"description",fn:function(){return [_vm._v(" Total number of streams executed ordered by status ")]},proxy:true},{key:"body",fn:function(){return [_c('v-row',{staticClass:"py-4",attrs:{"justify":"space-around"}},_vm._l((_vm.stream_counts),function(count,status){return _c('div',{key:status},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto pa-6 darkblue1--color py-3 capitalize text-center mb-0 font-weight-bold",attrs:{"elevation":hover ? 6 : 2}},[_vm._v(" "+_vm._s(status)+" "),_c('div',{staticClass:"py-3 text-center mb-0  font-weight-bold",class:{
                        'settled--color': status === 'settled',
                        'running--color': status === 'pushed',
                        'cancelled--color': status === 'cancelled',
                        'pending--color': status === 'paused',
                        'success--color': status === 'finished'
                      },staticStyle:{"font-size":"1.8rem"}},[_vm._v(" "+_vm._s(count)+" ")]),_c('div',{staticClass:"py-3 mb-0 font-weight-bold",class:{
                            'settled--color': status === 'settled',
                            'running--color': status === 'pushed',
                            'cancelled--color': status === 'cancelled',
                            'pending--color': status === 'paused',
                            'success--color': status === 'finished'
                      }},[_c('div',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"width":4,"size":50,"value":(count / _vm.stream_counts.total)*100}},[_vm._v(" "+_vm._s(Math.round((count / _vm.stream_counts.total)*100) || 0)+" "+_vm._s("%")+" ")])],1)])])]}}],null,true)})],1)}),0),(!_vm.showCount)?[_c('div',{staticClass:"text-center mt-5"},[_c('v-progress-circular',{attrs:{"size":40,"width":2,"color":"#0288D1","indeterminate":""}})],1)]:_vm._e()]},proxy:true}])})],1)],1),_c('v-row',{staticClass:"text-center"},[_c('v-col',{attrs:{"cols":"12"}},[_c('app-card',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Streams - Series by Status ")]},proxy:true},{key:"description",fn:function(){return [_vm._v(" Overall functions by status ")]},proxy:true},{key:"body",fn:function(){return [(_vm.showSeries)?[_c('apexchart',{attrs:{"type":"area","height":"400","options":_vm.barOptions,"series":_vm.barSeriesStream}})]:[_c('div',{staticClass:"text-center mt-5"},[_c('v-progress-circular',{attrs:{"size":40,"width":2,"color":"#0288D1","indeterminate":""}})],1)]]},proxy:true}])})],1)],1),_c('v-row',{staticClass:"text-center"},[_c('v-col',{attrs:{"cols":"12"}},[_c('app-card',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Jobs Count ")]},proxy:true},{key:"description",fn:function(){return [_vm._v(" Total number of jobs executed ordered by status ")]},proxy:true},{key:"body",fn:function(){return [_c('v-row',{staticClass:"py-4",attrs:{"justify":"space-around"}},_vm._l((_vm.jobs_counts),function(count,status){return _c('div',{key:status},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var hover = ref.hover;
return [_c('v-card',{staticClass:"pa-5 darkblue1--color py-3 text-center capitalize mb-0 font-weight-bold",staticStyle:{"width":"111.5px"},attrs:{"elevation":hover ? 6 : 2}},[_vm._v(" "+_vm._s(status)+" "),_c('div',{staticClass:"py-3 text-center mb-0 font-weight-bold",class:{
                            'running--color': status === 'running',
                            'pending--color': status === 'pending',
                            'blocked--color': status === 'blocked',
                            'cancelled--color': status === 'cancelled',
                            'success--color': status === 'completed'
                      },staticStyle:{"font-size":"1.8rem"}},[_vm._v(" "+_vm._s(count)+" ")]),_c('div',{staticClass:"py-3 mb-0 font-weight-bold",class:{
                            'running--color': status === 'running',
                            'pending--color': status === 'pending',
                            'blocked--color': status === 'blocked',
                            'cancelled--color': status === 'cancelled',
                            'success--color': status === 'completed'
                      }},[_c('div',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"width":4,"size":50,"value":(count / _vm.jobs_counts.total)*100}},[_vm._v(" "+_vm._s(Math.round(count / _vm.jobs_counts.total)*100 || 0)+_vm._s("%")+" ")])],1)])])]}}],null,true)})],1)}),0),(!_vm.showCount)?[_c('div',{staticClass:"text-center mt-5"},[_c('v-progress-circular',{attrs:{"size":40,"width":2,"color":"#0288D1","indeterminate":""}})],1)]:_vm._e()]},proxy:true}])})],1)],1),_c('v-row',{staticClass:"text-center"},[_c('v-col',{attrs:{"cols":"12"}},[_c('app-card',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Jobs - Series by Status ")]},proxy:true},{key:"description",fn:function(){return [_vm._v(" Overall functions by status ")]},proxy:true},{key:"body",fn:function(){return [(_vm.showSeries)?[_c('apexchart',{attrs:{"type":"area","height":"400","options":_vm.barOptions,"series":_vm.barSeriesJobs}})]:[_c('div',{staticClass:"text-center mt-5"},[_c('v-progress-circular',{attrs:{"size":40,"width":2,"color":"#0288D1","indeterminate":""}})],1)]]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }