














































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Function } from '@/js/schemas'
import { Prop } from 'vue-property-decorator'
import { getVariableTypeEmojiCode } from '@/js/utils'

@Component({})
export default class FunctionSpec extends Vue {
  @Prop()
  specData!: Function

  showEmoji (varType: string) {
    return getVariableTypeEmojiCode(varType)
  }
}
