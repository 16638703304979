













































import Component, { mixins } from 'vue-class-component'
import { mapState, mapActions, mapMutations } from 'vuex'
import { SessionDataMixin } from '@/js/utils'
import Sidebar from '@/components/Sidebar.vue'
import Topbar from '@/components/Topbar.vue'
import { ApiResponse } from '@/js/api'
import { WorkspaceListResource } from '@/js/schemas'

@Component({
  computed: {
    ...mapState(['isSessionActive'])
  },
  methods: {
    ...mapActions([
      'setCSRFToken',
      'checkActiveSession',
      'getServerVersion',
      'getApplicationConfig',
      'retrieveWorkspaceList'
    ]),
    ...mapMutations([
      'setIsSessionActive',
      'setServerVersion',
      'setAppConfigData',
      'setUserWorkspaces'
    ])
  },
  components: {
    Sidebar,
    Topbar
  }
})
export default class App extends mixins(SessionDataMixin) {
  $route: any
  $cookies: any
  isSessionActive!: boolean
  userWorkspaces!: WorkspaceListResource[]

  get csrfToken (): string {
    return this.$cookies.get('csrftoken')
  }

  get showLoader () {
    return this.$route.meta.requiresAuth && !this.showApplication
  }

  get showApplication (): boolean {
    return (
      this.isSessionActive &&
      this.sessionAccount !== null &&
      this.sessionWorkspace !== null
    )
  }

  get isSignRoute (): boolean {
    return (this.$route.name === 'SignIn' || this.$route.name === 'SignUp')
  }

  setCSRFToken!: () => Promise<ApiResponse>
  checkActiveSession!: () => Promise<ApiResponse>
  getServerVersion!: () => Promise<ApiResponse>
  getApplicationConfig!: () => Promise<ApiResponse>
  fetchSessionData!: () => Promise<undefined>
  retrieveWorkspaceList!: () => Promise<ApiResponse>
  setIsSessionActive!: (active: boolean) => undefined
  setServerVersion!: (version: string) => undefined
  setAppConfigData!: (config: any) => undefined
  setUserWorkspaces!: (workspaces: WorkspaceListResource[]) => undefined

  getBgSliderImage () {
    if (this.isSignRoute) {
      const images = require.context('@/assets/', false, /\.png$/)
      return images(`./bg-slider.png`)
    }
    return 'none'
  }

  beforeMount () {
    if (!this.csrfToken) {
      this.setCSRFToken()
    }
  }

  mounted () {
    this.checkActiveSession().then(async (apiResponse: ApiResponse) => {
      const active = (apiResponse.content.body as any).success
      if (active) {
        await this.fetchSessionData()
        const apiResponse = await this.retrieveWorkspaceList()
        this.setUserWorkspaces(
          apiResponse.content.body.data as WorkspaceListResource[]
        )
        this.setIsSessionActive(true)
      } else {
        this.setIsSessionActive(false)
      }
    })
    this.getServerVersion().then((apiResponse: ApiResponse) => {
      this.setServerVersion(apiResponse.content.body.version)
    })
    this.getApplicationConfig().then((apiResponse: ApiResponse) => {
      this.setAppConfigData(apiResponse.content.body)
    })
  }
}
