import { ActionContext } from 'vuex/types'
import { JobListResource, JobMetadata } from '@/js/schemas'
import { RootStateData } from '@/store/root'
import { ApiResponse, doEntitiesApiCall, handleApiResponse } from '@/js/api'

interface JobsStateData {
  jobs: JobListResource[]
  jobsMetadata: JobMetadata []
}

// initial state
const state: JobsStateData = {
  jobs: [],
  jobsMetadata: []
}

type JobsContext = ActionContext<JobsStateData, RootStateData>

// getters
const getters = {}

// actions
const actions = {
  // Stream List
  async retrieveJobList (
    context: JobsContext,
    filterParams: Record<string, any>
  ): Promise<ApiResponse> {
    return handleApiResponse(
      await doEntitiesApiCall(
        {
          url: 'jobs/',
          method: 'get',
          params: { ...filterParams } // TODO: Remove this
        }
      )
    )
  },
  // Job Retrieve
  async retrieveJobDetail (
    context: JobsContext,
    uuid: string
  ): Promise<ApiResponse> {
    return handleApiResponse(
      await doEntitiesApiCall(
        {
          url: `jobs/${uuid}/`,
          method: 'get'
        }
      )
    )
  },

  // Job metadata Retrieve
  async retrieveJobMetadata (
    context: JobsContext,
    uuid: string
  ): Promise<ApiResponse> {
    return handleApiResponse(
      await doEntitiesApiCall(
        {
          url: `jobs/metadata/${uuid}/`,
          method: 'get'
        }
      )
    )
  },

  async retrieveJobMetadataList (
    context: JobsContext,
    filterParams: Record<string, string>
  ): Promise<ApiResponse> {
    return handleApiResponse(
      await doEntitiesApiCall(
        {
          url: `job-runs/`,
          method: 'get',
          params: { ...filterParams }
        }
      )
    )
  }
}

// mutations
const mutations = {
  setJobs (state: JobsStateData, jobs: JobListResource[]) {
    state.jobs = jobs
  },

  setJobsMetada (state: JobsStateData, jobsMetadata: JobMetadata[]) {
    state.jobsMetadata = jobsMetadata
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
