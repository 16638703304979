import { ActionContext } from 'vuex/types'
import { Count } from '@/js/schemas'
import { RootStateData } from '@/store/root'
import { ApiResponse, doDashboardApiCall, handleApiResponse } from '@/js/api'

interface StateData {
  count: Count[]
}

// initial state
const state: StateData = {
  count: []
}

type DashboardContext = ActionContext<StateData, RootStateData>

// getters
const getters = {}

// actions
const actions = {
  // Stream List
  async retrieveCountList (
    context: DashboardContext
  ): Promise<ApiResponse> {
    return handleApiResponse(
      await doDashboardApiCall(
        {
          url: 'count/',
          method: 'get',
          params: { limit: 1000 } // TODO: Remove this
        }
      )
    )
  },

  async retrieveDataSeriesList (
    context: DashboardContext
  ): Promise<ApiResponse> {
    return handleApiResponse(
      await doDashboardApiCall(
        {
          url: 'data-series/',
          method: 'get',
          params: { limit: 1000 }
        }
      )
    )
  }

}

// mutations
const mutations = {
  setCount (state: StateData, count: Count[]) {
    state.count = count
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
